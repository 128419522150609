import orderBy from "lodash/orderBy";
import { BehaviorSubject, Observable, Subject, timer } from "rxjs";
import { FeaturesEntry } from "src/app/data_model/features";
import { LoggedInUserEntry } from "src/app/data_model/logged-in-user";
import { PracticeEntry } from "src/app/data_model/practice";
import { SiteEntry } from "src/app/data_model/site";
import { Constants } from "src/constants";
import { getCurrencySymbol } from "@angular/common";
import { Injectable } from "@angular/core";
import { CookieService } from "./cookie.service";
import { HttpService } from "./http.service";
import { JWTService } from "./jwt.service";
import { PushUpdatesService } from "./push-updates.service";
import { Pusher_subscribeToRefreshCache } from "./pusher-callbacks/refresh-cache-sync";
import { SiteSettingsEntry } from "src/app/data_model/site-settings";
import { BrandEntry } from "src/app/data_model/brands";
import { filter, shareReplay, take } from "rxjs/operators";
import { FeatureFlagsService } from "./feature-flags.service";
import { FeatureFlagEntry } from "src/app/data_model/feature-flag";
import { FeatureFlagBase } from "../../../../../../backend/src/graph/feature_flags/feature-flag-base";
import { PracticeSettingsEntry } from "src/app/data_model/practice-settings";
import { Pusher_subscribeToPracticeSettings } from "src/app/main/pages/settings/pusher-callbacks/practice-settings";
import { NotificationService } from "./notification.service";
import { LoggedInUserSettingsEntry } from "src/app/data_model/logged-in-user-settings";
import { LocalStorageService } from "./local-storage.service";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import Bugsnag from "@bugsnag/js";
import { environment } from "src/environments/environment";
import { isManageAppStatisticsSupported } from "../../../../../../shared/aws-features";
import { SessionService } from "./session.service";
import { InactivityMonitorService } from "./inactivity-monitor.service";
import { filterDefined } from "../utils/rxjs";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export enum E_PMS_NAME {
  DENTALLY = "Dentally",
  EXACT = "Exact",
}

export enum E_AdditionalSiteData {
  APPLIED_BRAND = "applied_brand",
  DEFAULT_PAYMENT_PLAN = "default_payment_plan",
  OWNED_BRAND = "owned_brand",
}

export enum E_SiteDataLoadingStatus {
  NOT_LOADED = "not loaded",
  LOADING = "loading",
  LOADED = "loaded",
}

export class CommonEntry {
  public practice: PracticeEntry;
  public logged_in_user: LoggedInUserEntry;
  public feature_flags: Array<FeatureFlagEntry>;
}

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private static readonly _COMMON_REQUEST = `{
    practice {
      id
      refresh_status
      refresh_syncing
      last_refresh_time
      last_complete_sync_time
      portal_online_booking_price
      portal_online_signing_price
      practitioners(filters: [{ field: "active", value: "true" }]) {
        items {
          id
        }
      }
      features {
        practitioner_profiles
        branding
        devices
        online_booking
        online_signing
        payments
        plans
      }
      settings {
        l3_permission__can_edit_appointment_settings
        l3_permission__can_edit_appointments
        l3_permission__can_edit_customise_portal
        l3_permission__can_edit_customise_practitioners
        l3_permission__can_edit_online_signing
        l3_permission__can_edit_patient_notifications
        l3_permission__can_edit_payment_plans
        l3_permission__can_edit_payment_types
        l3_permission__can_edit_practice_notifications
        l3_permission__can_edit_task_reminder_notifications
        l3_permission__can_edit_patient_profile
        l3_permission__can_pair_device
        l3_permission__can_unlock_device
        l2_permission__can_pair_device
        l2_permission__can_unlock_device
        l1_permission__can_pair_device
        l1_permission__can_unlock_device
      }
      logo_url
      currency_code
      time_zone
      name
      nhs
      nhs_country
      iso_country_code
      is_multisite
      hso_region
      requires_migration
      supports_nhs_pr
      sites {
        items {
          id
          name
          email_address
          active_mailbox_address
          active
          logo_url
          stripe_account_id
        }
      }
    }
    logged_in_user {
      first_name
      image_url
      last_name
      role
      title
      email
      created_at
      intercom_hash
    }
    feature_flags {
      items {
        name
        value
      }
    }
  }`;
  private static readonly _ADDITIONAL_SITES_DATA_REQUEST = {
    [E_AdditionalSiteData.DEFAULT_PAYMENT_PLAN]: `{
      id
      default_payment_plan {
        payment_plan {
          name
          patient_friendly_name
        }
      }
    }`,
    [E_AdditionalSiteData.OWNED_BRAND]: `{
      id
      owned_brand {
        id
        owner
        display_name
        used_custom_domain {
          custom_domain_id
          custom_domain {
            id
            domain
          }
        }
        used_default_domain {
          default_domain_id
          default_domain {
            id
            domain
          }
          subdomain
        }
        colour
        logo_url
        override_practice_brand_theme
      }
    }`,
    [E_AdditionalSiteData.APPLIED_BRAND]: `{
      id
      applied_brand {
        id
        owner
        display_name
        used_custom_domain {
          custom_domain_id
          custom_domain {
            id
            domain
          }
        }
        used_default_domain {
          default_domain_id
          default_domain {
            id
            domain
          }
          subdomain
        }
        colour
        logo_url
        override_practice_brand_theme
      }
    }`,
  };

  private _selectedSiteId: string | null = null;
  private _isRefreshing = false;
  private _refreshMessage: string | null;
  private _lastRefreshTime: dayjs.Dayjs;
  private _lastCompleteSyncTime: dayjs.Dayjs;
  private _commonData: CommonEntry;
  private _practiceInit = false;
  private _additionalSiteDataStatus: Record<E_AdditionalSiteData, Record<string, Observable<string>>> = {
    [E_AdditionalSiteData.APPLIED_BRAND]: {},
    [E_AdditionalSiteData.DEFAULT_PAYMENT_PLAN]: {},
    [E_AdditionalSiteData.OWNED_BRAND]: {},
  };

  public ALL_SITES = new SiteEntry();
  public onSelectedSiteChanged = new BehaviorSubject(this._selectedSiteId);
  private _onPracticeDataReady = new BehaviorSubject<boolean | null>(null);
  public PMS_NAME = E_PMS_NAME.DENTALLY;
  public onPracticeSettingsChanged = new Subject<PracticeSettingsEntry>();
  public onSantaClickReset = new Subject<void>();
  private _showSanta = false;

  constructor(
    private _cookieService: CookieService,
    private _localStorageService: LocalStorageService,
    private _httpService: HttpService,
    private _jwtService: JWTService,
    private _pushUpdateService: PushUpdatesService,
    private _featureFlagsService: FeatureFlagsService,
    private _notificationService: NotificationService,
    private _sessionService: SessionService,
    private _inactivityMonitorService: InactivityMonitorService
  ) {
    this.ALL_SITES.id = Constants.ALL_SITES_ID;
    this.ALL_SITES.name = Constants.ALL_SITES_NAME;
    Pusher_subscribeToRefreshCache(this, this._pushUpdateService);
    Pusher_subscribeToPracticeSettings(this._pushUpdateService, this._notificationService, this);
  }

  public get onPracticeDataReady(): Observable<boolean> {
    return this._onPracticeDataReady.pipe(filterDefined());
  }

  private _initRefreshStatus(refresh_status: string | null, refresh_syncing: boolean, last_refresh_time: Date | null, last_complete_sync_time: Date | null) {
    this.refreshMessage = refresh_status;
    this.lastRefreshTime = dayjs.utc(last_refresh_time);
    this.lastCompleteSyncTime = dayjs.utc(last_complete_sync_time);

    this.isRefreshing = refresh_syncing;
  }

  public initPracticeData(): void {
    if (this._practiceInit) return;

    this._httpService.query<any>(CommonService._COMMON_REQUEST).subscribe((commonDataResponse) => {
      this._commonData = new CommonEntry();
      this._commonData.practice = new PracticeEntry(commonDataResponse.practice);
      this._commonData.logged_in_user = new LoggedInUserEntry(commonDataResponse.logged_in_user);
      this._commonData.logged_in_user.settings = new LoggedInUserSettingsEntry(commonDataResponse.logged_in_user.settings);
      this._commonData.feature_flags = commonDataResponse.feature_flags.items.map((ff: FeatureFlagBase) => {
        return new FeatureFlagEntry(ff);
      });
      const sites = new Array<SiteEntry>();
      for (const site of this._commonData.practice.sites.items) {
        const new_site = new SiteEntry(site);
        new_site.settings = new SiteSettingsEntry(site.settings);
        sites.push(new_site);
      }

      this._commonData.practice.sites.items = orderBy(sites, ["name"], ["asc"]);

      if (!this._commonData.practice) return;
      this._initRefreshStatus(
        this._commonData.practice.refresh_status,
        this._commonData.practice.refresh_syncing,
        this._commonData.practice.last_refresh_time,
        this._commonData.practice.last_complete_sync_time
      );

      const previousSite = this._cookieService.getCookie(Constants.SITE_SELECTION_COOKIE);
      if (this.sites.find((site) => site.id === previousSite)) {
        this.selectedSiteId = previousSite;
      } else if (this._jwtService.getJWT().permission_level === 3) {
        this.selectedSiteId = this.sites[0].id;
      } else {
        this.selectedSiteId = this.ALL_SITES.id;
      }

      const alreadySeenSanta = this._localStorageService.getItem(Constants.SANTA_STORAGE_NAME);
      if (dayjs().format("MM") === "12" && dayjs().format("DD") === "25" && !alreadySeenSanta) {
        this.showSanta = true;
        this._localStorageService.setItem(Constants.SANTA_STORAGE_NAME, true);
      }

      this._practiceInit = true;
      this._onPracticeDataReady.next(this._practiceInit);
      if (!this.isMultiSite) this.selectedSiteId = this._commonData.practice.sites.items[0].id;

      this._preloadAdditionalSiteData();

      this._featureFlagsService.setFeatureFlags(this._commonData.feature_flags);

      this._startInactivityMonitor();
    });
  }

  public get isL1OrHigher(): boolean {
    return this._jwtService.getJWT().permission_level >= 1;
  }

  public get isL1(): boolean {
    return this._jwtService.getJWT().permission_level === 1;
  }

  public get isL2OrHigher(): boolean {
    return this._jwtService.getJWT().permission_level >= 2;
  }

  public get isL2(): boolean {
    return this._jwtService.getJWT().permission_level === 2;
  }

  public get isL3(): boolean {
    return this._jwtService.getJWT().permission_level === 3;
  }

  public get isL3OrHigher(): boolean {
    return this._jwtService.getJWT().permission_level >= 3;
  }

  public get isL4OrHigher(): boolean {
    return this._jwtService.getJWT().permission_level >= 4;
  }

  public get isL5(): boolean {
    return this._jwtService.getJWT().permission_level === 5;
  }

  public get isRestrictedMode(): boolean {
    return !this.feat_OnlineBooking && !this.feat_OnlineSigning;
  }

  public get permissionLevel(): number {
    return this._jwtService.getJWT().permission_level;
  }

  public get selectedSite(): SiteEntry | null {
    return this.sites.find((site) => site.id === this.selectedSiteId) || null;
  }

  public get selectedSiteId(): string | null {
    return this._selectedSiteId;
  }

  public set selectedSiteId(value: string | null) {
    let newVal = value;
    if (!this.isMultiSite || (value === this.ALL_SITES.id && this._jwtService.getJWT().permission_level < 4)) {
      newVal = this.sites[0].id;
    }

    this._selectedSiteId = newVal;
    this.onSelectedSiteChanged.next(newVal);
    if (newVal) {
      this._cookieService.setCookie(Constants.SITE_SELECTION_COOKIE, newVal);
    } else {
      this._cookieService.deleteCookie(Constants.SITE_SELECTION_COOKIE);
    }
  }

  public enableWinter = dayjs().format("MM") === "12";

  public set showSanta(value: boolean) {
    this._showSanta = value;
    if (!value) this.onSantaClickReset.next();
  }

  public get showSanta(): boolean {
    if (!this.enableWinter) return false;
    return this._showSanta;
  }

  public get lastRefreshTime(): dayjs.Dayjs {
    return this._lastRefreshTime;
  }

  public set lastRefreshTime(lastRefreshTime: dayjs.Dayjs) {
    this._lastRefreshTime = lastRefreshTime;
  }

  public get lastCompleteSyncTime(): dayjs.Dayjs {
    return this._lastCompleteSyncTime;
  }

  public set lastCompleteSyncTime(lastCompleteSyncTime: dayjs.Dayjs) {
    this._lastCompleteSyncTime = lastCompleteSyncTime;
  }

  public get isRefreshing(): boolean {
    return this._isRefreshing;
  }

  public set isRefreshing(value: boolean) {
    this._isRefreshing = value;
  }

  public get refreshMessage(): string {
    if (!this._refreshMessage) return "";
    return this._refreshMessage;
  }

  public set refreshMessage(value: string | null) {
    if (!value) return; //on first setup value will be null
    const parts = value.split("{{");

    if (parts.length === 1) {
      this._refreshMessage = value;
    } else {
      const beforeDate = parts[0];
      const subParts = parts[1].split("}}");
      const date = dayjs(subParts[0]).format("llll");
      const afterDate = subParts[1];
      this._refreshMessage = `${beforeDate}${date}${afterDate}`;
    }
  }

  public get nhsSupportsOnlineSigning(): boolean {
    const allowed_nhs_countries = ["EN", "WA"];
    return !!(this.practice?.supports_nhs_pr && allowed_nhs_countries.includes(this?.practice.nhs_country));
  }

  public get nhsFormName(): string {
    const { nhs_country } = this.practice;

    if (!nhs_country) return "";

    switch (nhs_country.toLowerCase()) {
      case "sc":
        return "NHS GP17";
      case "wa":
      case "en":
        return "NHS FP17";
      default:
        return "";
    }
  }

  public get practice(): PracticeEntry {
    return this._commonData.practice;
  }

  public get sites(): Array<SiteEntry> {
    return this._commonData.practice.sites.items;
  }

  public get isMultiSite(): boolean {
    return this._commonData.practice.is_multisite;
  }

  public get features(): FeaturesEntry {
    return this._commonData.practice.features;
  }

  public get currency(): string {
    return getCurrencySymbol(this.currency_code, "narrow");
  }

  public get currency_code(): string {
    return this._commonData.practice.currency_code;
  }

  public get country_code(): string {
    return this._commonData.practice.iso_country_code;
  }

  public get hso_region(): string {
    return this._commonData.practice.hso_region;
  }

  public get locale_code(): string {
    switch (this._commonData.practice.iso_country_code) {
      case "GB":
        return "en-gb";
      case "AU":
        return "en-au";
      default:
        return this._commonData.practice.iso_country_code;
    }
  }

  public get time_zone(): string {
    return this._commonData.practice.time_zone;
  }

  public get user(): LoggedInUserEntry {
    return this._commonData.logged_in_user;
  }

  public get userSettings(): LoggedInUserSettingsEntry {
    return this._commonData.logged_in_user.settings;
  }

  public get isAllSites(): boolean {
    return this.selectedSiteId === this.ALL_SITES.id;
  }

  // #region Practice Settings
  public get practiceSettings(): PracticeSettingsEntry {
    return this._commonData.practice.settings;
  }

  public set practiceSettings(value: PracticeSettingsEntry) {
    this._commonData.practice.settings = value;
  }

  public get hasPermission_AppointmentSettings(): boolean {
    return this.isL4OrHigher || (this._commonData.practice.settings.l3_permission__can_edit_appointment_settings && this.isL3);
  }

  public get hasPermission_Appointments(): boolean {
    return this.isL4OrHigher || (this._commonData.practice.settings.l3_permission__can_edit_appointments && this.isL3);
  }

  public get hasPermission_CustomisePortal(): boolean {
    return this.isL4OrHigher || (this._commonData.practice.settings.l3_permission__can_edit_customise_portal && this.isL3);
  }

  public get hasPermission_CustomisePractitioners(): boolean {
    return this.isL4OrHigher || (this._commonData.practice.settings.l3_permission__can_edit_customise_practitioners && this.isL3);
  }

  public get hasPermission_OnlineSigning(): boolean {
    return this.isL4OrHigher || this._commonData.practice.settings.l3_permission__can_edit_online_signing;
  }

  public get hasPermission_PatientNotifications(): boolean {
    return this.isL4OrHigher || (this._commonData.practice.settings.l3_permission__can_edit_patient_notifications && this.isL3);
  }

  public get hasPermission_PaymentPlans(): boolean {
    return this.isL4OrHigher || (this._commonData.practice.settings.l3_permission__can_edit_payment_plans && this.isL3);
  }

  public get hasPermission_PaymentTypes(): boolean {
    return this.isL4OrHigher || (this._commonData.practice.settings.l3_permission__can_edit_payment_types && this.isL3);
  }

  public get hasPermission_PracticeNotifications(): boolean {
    return this.isL4OrHigher || (this._commonData.practice.settings.l3_permission__can_edit_practice_notifications && this.isL3);
  }

  public get hasPermission_DevicePairing(): boolean {
    if (this.isL4OrHigher) return true;
    if (this.isL3) return this._commonData.practice.settings.l3_permission__can_pair_device;
    if (this.isL2) return this._commonData.practice.settings.l2_permission__can_pair_device;
    if (this.isL1) return this._commonData.practice.settings.l1_permission__can_pair_device;

    return false;
  }

  public get hasPermission_DeviceUnlocking(): boolean {
    if (this.isL4OrHigher) return true;
    if (this.isL3) return this._commonData.practice.settings.l3_permission__can_unlock_device;
    if (this.isL2) return this._commonData.practice.settings.l2_permission__can_unlock_device;
    if (this.isL1) return this._commonData.practice.settings.l1_permission__can_unlock_device;

    return true;
  }
  // #endregion

  public get feat_Payments(): boolean {
    return this.features.payments;
  }

  public get feat_Plans(): boolean {
    return this.features.plans;
  }

  public get feat_OnlineBooking(): boolean {
    return this.features.online_booking;
  }

  public get feat_OnlineSigning(): boolean {
    return this.features.online_signing;
  }

  public get feat_CustomisePractitioners(): boolean {
    return this.features.practitioner_profiles;
  }

  public get feat_CustomisePortal(): boolean {
    return this.features.branding;
  }

  public get feat_CustomiseBrands(): boolean {
    return this.isMultiSite && this.isL4OrHigher && this.features.branding;
  }

  public get feat_Devices(): boolean {
    const bupaPracticeIds: Array<string> = [
      "18dfdce9-cdaa-4e4f-a48e-ee7a54720800", // Bupa Dental Care
      "757e5dbb-1dbf-4e1a-ab2f-e0450161df7e", // Bupa Dental Care - Wales
      "61eaa005-d0d8-4830-bdaa-db3219b1ccf7", // Bupa Dental Care - Scotland
      "6dcce0e0-8cdb-42dc-9fda-31c987fd83a5", // Bupa - PRODTEST
      "63d4ca79-9c73-4164-b127-e4dc82f376ec", // Bupa Dental Care - NI
      "edea802a-6b33-432d-9aca-168dd9bf7e13", // Smiles Dental Care
      "f9a2d72b-5ca5-486b-9422-a7e58f4d38d7", // Bupa Dental Care - SA
      "ef77a9e9-1816-4caf-b4af-078ba5710eb7", // Bupa Dental Care - AEST
      "0688b213-5dba-40e0-b574-72767c641233", // Bupa Dental Care - WA
      "1d81cb63-d0b1-4f72-8bae-a17cbada3a2c", // Bupa Dental Care - NT
      "4b3dda7c-2eaa-4f76-97ed-48c49a5fbd02", // Bupa Dental Care - QLD
    ];

    if (this.practice?.id && bupaPracticeIds.includes(this.practice.id)) {
      return false;
    }

    return this.features.devices;
  }

  public get feat_L2_L1_Login(): boolean {
    return this._featureFlagsService.L2_L1_Login;
  }

  public get feat_MetricsCharts(): boolean {
    return isManageAppStatisticsSupported(environment.REGION);
  }

  public async refreshCache() {
    if (this.isRefreshing) return;
    this.isRefreshing = true;
    try {
      await this._httpService.fetchData<any>(
        `mutation {
          queueRefreshCache
        }`,
        "sync with Dentally"
      );
    } catch (err) {
      this.isRefreshing = false;
    }
  }

  public loadAdditionalSiteData(key: E_AdditionalSiteData): Observable<string> {
    const selectedSiteId = this._selectedSiteId;

    if (!selectedSiteId) {
      Bugsnag.notify(new Error("Cannot load additional site data when no site is selected"));
      throw new Error("Cannot load additional site data when no site is selected");
    }

    if (this._additionalSiteDataStatus[key][this.ALL_SITES.id] !== undefined) return this._additionalSiteDataStatus[key][this.ALL_SITES.id];

    if (this._additionalSiteDataStatus[key][selectedSiteId] === undefined) {
      this._additionalSiteDataStatus[key][selectedSiteId] = new Observable<string>((observer) => {
        let query = CommonService._ADDITIONAL_SITES_DATA_REQUEST[key];

        if (selectedSiteId === this.ALL_SITES.id) {
          // Don't use isAllSites because the selected site ID could be different
          query = `{
            practice {
              sites {
                items ${query}
              }
            }
          }`;
        } else {
          query = `{
            practice {
              site(site_id: "${selectedSiteId}") ${query}
            }
          }`;
        }

        this._httpService
          .fetchData<CommonEntry>(query)
          .then((response) => {
            for (const newSiteData of response.practice.sites ? response.practice.sites.items : [response.practice.site]) {
              if (!newSiteData) continue;

              const site = this._commonData.practice.sites.items.find((existingSiteData) => existingSiteData.id === newSiteData.id) as any;

              // Only set the data if it's not been loaded from elsewhere
              if (site[key] && Object.keys(site[key]).length) continue;

              if (key === E_AdditionalSiteData.OWNED_BRAND) site[key] = new BrandEntry(newSiteData[key]);
              else site[key] = newSiteData[key];
            }

            observer.next(selectedSiteId);
            observer.complete();
          })
          .catch((error) => {
            observer.error(error);
          });
      }).pipe(shareReplay(1)); // Share the Observable so that subsequent calls don't trigger another GraphQL call
    }

    return this._additionalSiteDataStatus[key][selectedSiteId].pipe(filter((siteId) => siteId === selectedSiteId || siteId === this.ALL_SITES.id));
  }

  /**
   * Preloads additional site data which is used by some pages but is not needed when the application first loads
   *
   * Waits a second before loading additional data to allow for pages which are loaded initially which require certain data
   */
  private _preloadAdditionalSiteData(): void {
    timer(1000).subscribe(() => {
      Object.values(E_AdditionalSiteData).forEach((value) => {
        this.loadAdditionalSiteData(value).pipe(take(1)).subscribe();
      });
    });
  }

  private _startInactivityMonitor(): void {
    if (!this._featureFlagsService.logoutOnInactivity) {
      return;
    }

    this._inactivityMonitorService.start();
  }

  public async logout(): Promise<void> {
    await this._sessionService.logout();
  }
}
