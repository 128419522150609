/* eslint-disable import/no-cycle */
import forOwn from "lodash/forOwn";

import { BaseListBase } from "../../../../../backend/src/graph/_resolvers/base/base-list-base";
import { SiteBase } from "../../../../../backend/src/graph/sites/site-base";
import { BrandEntry } from "./brands";
import { PaymentPlanEntry } from "./payment-plan";
import { PractitionerEntry } from "./practitioner";
import { RecurringAppointmentEntry } from "./recurring-appointment";
import { SiteAllowedAppointmentTypeListEntry } from "./site-allowed-appointment-type";
import { SiteAppointmentTypeEntry } from "./site-appointment-type";
import { SitePaymentPlanEntry } from "./site-payment-plan";
import { SiteSettingsEntry } from "./site-settings";
import { SitePaymentPlanCategoryEntry } from "./site-payment-plan-category";
import { StatisticsEntry } from "./statistics";
import { UrlSiteAliasEntry } from "./url-site-alias";

export class SiteEntry extends SiteBase {
  public allowed_appointment_type: SiteAllowedAppointmentTypeListEntry;
  public applied_brand: BrandEntry;
  public default_payment_plan: {
    payment_plan: PaymentPlanEntry;
  };
  public online_signing_statistics: Array<StatisticsEntry>;
  public appointments_statistics: Array<StatisticsEntry>;
  public owned_brand: BrandEntry;
  public practitioners: BaseListBase<PractitionerEntry>;
  public recurring_appointments: BaseListBase<RecurringAppointmentEntry>;
  public settings: SiteSettingsEntry;
  public site_appointment_types: BaseListBase<SiteAppointmentTypeEntry>;
  public site_payment_plan_categories: BaseListBase<SitePaymentPlanCategoryEntry>;
  public site_payment_plans: BaseListBase<SitePaymentPlanEntry>;
  public url_site_alias: UrlSiteAliasEntry;
  public get display_name(): string {
    return this.nickname || this.name;
  }

  constructor(baseItem?: SiteBase, applied_brand?: BrandEntry) {
    super();

    forOwn(baseItem, (value, key) => {
      switch (key) {
        case "applied_brand":
          if (applied_brand) {
            this[key] = applied_brand;
          } else {
            this[key] = new BrandEntry(value);
          }
          break;

        case "owned_brand":
          this[key] = new BrandEntry(value);
          break;

        default:
          this[key] = value;
      }
    });

    if (this.url_site_alias) this.url_site_alias = new UrlSiteAliasEntry(this.url_site_alias, this.applied_brand);
  }
}

export class SiteListEntry extends BaseListBase<SiteEntry> {}
