import { SHARED } from "src/app/shared/shared";
import { Component, Input } from "@angular/core";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { heroClock, heroSparkles } from "@ng-icons/heroicons/outline";
import { DevService } from "../../services/dev.service";
import { OverlayService } from "../../services/overlay.service";
import { DevViewerModalComponent } from "../dev-viewer/dev-viewer-modal.component";
import { HistoryViewerModalComponent } from "../history-viewer/history-viewer-modal.component";

@Component({
  selector: "dentr-dev-button",
  templateUrl: "./dev-button.component.html",
  host: { class: "dev-button z-50 absolute -top-2", "[class.-left-10]": "show_history", "[class.-left-5]": "!show_history" },
  standalone: true,
  imports: [NgIconComponent, SHARED],
  providers: [provideIcons({ heroClock, heroSparkles })],
})
export class DevButtonComponent {
  @Input() item_id: string | null;
  @Input() item_type: string;
  @Input() public show_history = true;

  constructor(public devService: DevService, private _overlayService: OverlayService) {}

  public async showObjectDetails($event: MouseEvent) {
    $event.stopPropagation();
    if (!this.item_id) return;
    await this.devService.setObjectDetails(this.item_id, this.item_type);
    this._overlayService.open({ component: DevViewerModalComponent });
  }

  public async showObjectHistory($event: MouseEvent) {
    $event.stopPropagation();
    if (!this.item_id) return;
    await this.devService.setObjectHistory(this.item_id, this.item_type);
    this._overlayService.open({ component: HistoryViewerModalComponent });
  }
}
