import { SHARED } from "src/app/shared/shared";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, ViewChild } from "@angular/core";
import { Enums } from "src/enums";
import { DevicesIphone14ProComponent } from "./iphone-14-pro/iphone-14-pro.component";

@Component({
  selector: "dentr-devices",
  templateUrl: "./devices.component.html",
  host: { class: "origin-top-left block" },
  standalone: true,
  imports: [SHARED, DevicesIphone14ProComponent],
})
export class DevicesComponent implements AfterViewInit {
  @ViewChild("content") public content: ElementRef;
  public DEVICES = Enums.DEVICES;
  public hasContent: boolean;
  public video = {
    src: "",
    poster: "",
    type: "mp4",
  };

  @Input() device: Enums.DEVICES;
  @Input() size?: Enums.E_DEVICES_SIZE = Enums.E_DEVICES_SIZE.MD;
  @Input() showVideo?: boolean = false;

  @HostBinding("class") get scale() {
    switch (this.size) {
      case Enums.E_DEVICES_SIZE.LG:
        return "scale-100";
      case Enums.E_DEVICES_SIZE.SM:
        return "scale-50";
      default:
        return "scale-75";
    }
  }

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterViewInit() {
    this.hasContent = this.content?.nativeElement.getElementsByClassName("device-content")[0]?.childNodes.length > 0;
    this.prepareVideo();
    this._changeDetectorRef.detectChanges();
  }

  public ngOnChanges(_) {
    this.prepareVideo();
  }

  public prepareVideo() {
    const video = this.content?.nativeElement?.querySelector("video");
    if (!video) return;

    if (!this.hasContent || this.showVideo) {
      this._generateContentPlaceholder();
      video.muted = true;
      video.play();
    } else if (video && video.stop) {
      video.stop();
    }
  }

  private _generateContentPlaceholder() {
    switch (this.device) {
      case Enums.DEVICES.IPHONE_14_PRO:
        this.video.src = "iphone-bg.mp4";
        this.video.poster = "iphone-bg-poster.png";
    }
  }
}
