/* eslint-disable import/no-cycle */
import { AfterViewInit, Component, ContentChild, ElementRef, forwardRef, OnInit } from "@angular/core";
import { DropdownContentComponent } from "./dropdown-content/dropdown-content.component";
import { DropdownTriggerComponent } from "./dropdown-trigger/dropdown-trigger.component";

@Component({
  selector: "dentr-dropdown",
  templateUrl: "./dropdown.component.html",
  standalone: true,
})
export class DropdownComponent implements AfterViewInit, OnInit {
  @ContentChild(DropdownTriggerComponent, { read: ElementRef })
  trigger: ElementRef;
  @ContentChild(forwardRef(() => DropdownContentComponent), { read: ElementRef })
  content: ElementRef;
  private _$contentEl: HTMLElement;

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    this._$contentEl = this._elementRef.nativeElement.querySelector("dentr-dropdown-content");
  }

  ngAfterViewInit(): void {
    this._setAriaAttributes();
    this._bindEvents();
  }

  private _closeAllDropdowns(): void {
    const dropdowns = Array.from(document.querySelectorAll("dentr-dropdown"));
    dropdowns.forEach((el) => {
      const content = el.querySelector("dentr-dropdown-content");
      content?.classList.remove("block");
      content?.classList.add("hidden");
    });

    this.content.nativeElement.setAttribute("aria-expanded", "false");
  }

  private _setAriaAttributes(): void {
    if (this.trigger.nativeElement) {
      if (this.trigger.nativeElement.childNodes[0].innerHTML) {
        this.trigger.nativeElement.childNodes[0].setAttribute("aria-haspopup", "true");
        this.trigger.nativeElement.childNodes[0].setAttribute("tabindex", "0");
      }
    }
  }

  private _bindEvents(): void {
    // Binding one event rather than multiple on the window when multiple instances of component exist
    this._elementRef.nativeElement.closest("body").onclick = () => {
      this._closeAllDropdowns();
      this.content.nativeElement.setAttribute("aria-expanded", false);
    };

    this.trigger.nativeElement.onclick = ($event: MouseEvent) => {
      $event.stopPropagation();

      // Ensures existing dropdowns close when opening a new one (multiple instances on page)
      if (this._$contentEl.classList.contains("hidden")) {
        this._closeAllDropdowns();
      }

      this.toggleDropdown();

      this.content.nativeElement.setAttribute("aria-expanded", this._$contentEl.classList.contains("block"));
    };
  }

  public toggleDropdown(): void {
    this._$contentEl.classList.toggle("block");
    this._$contentEl.classList.toggle("hidden");
  }
}
