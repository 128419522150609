import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { SupportQueryEntry } from "src/app/data_model/support-query";
import { DevService } from "./dev.service";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class SupportToolsService {
  constructor(private _httpService: HttpService, private _commonService: CommonService, private _devService: DevService) {}

  public get showSupportTools(): boolean {
    return this._devService.isUserImpersonating || this._commonService.isL5;
  }

  public async clearApiCache(): Promise<void> {
    const result: any = await this._httpService.fetchData(`
      mutation {
        clearApiCache {
          success
        }
      }`);
    return result.clearApiCache;
  }

  public async getAvailableQueries(): Promise<Array<SupportQueryEntry>> {
    const result: any = await this._httpService.fetchData(
      `query {
        support_query_list {
          id
          name
          description
        }
        }`
    );
    return result.support_query_list;
  }

  public async runQuery(queryId: string): Promise<string> {
    const result: any = await this._httpService.fetchData(
      `query {
        support_query_result(query_id: "${queryId}")
        }`
    );
    return result.support_query_result;
  }
}
