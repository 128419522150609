import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonService } from "src/app/shared/services/common.service";
import { SHARED } from "src/app/shared/shared";
import { StockingsComponent } from "../stockings/stockings.component";

@Component({
  selector: "dentr-content",
  templateUrl: "./content.component.html",
  host: { class: "block fixed top-0 pt-16 left-72 bottom-0 right-0 overflow-y-auto" },
  standalone: true,
  imports: [RouterModule, SHARED, StockingsComponent],
})
export class ContentComponent {
  constructor(public commonService: CommonService) {}
}
