import { SHARED } from "src/app/shared/shared";
import { Component } from "@angular/core";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { CommonService } from "src/app/shared/services/common.service";
import { heroArrowPathSolid } from "@ng-icons/heroicons/solid";

@Component({
  selector: "dentr-refresh-cache",
  templateUrl: "./refresh-cache.component.html",
  standalone: true,
  imports: [SHARED, NgIconComponent],
  providers: [provideIcons({ heroArrowPathSolid })],
})
export class RefreshCacheComponent {
  constructor(public commonService: CommonService) {}

  public async resync() {
    await this.commonService.refreshCache();
  }
}
