export enum E_AwsFeatures {
  Evidently = "Evidently",
  Lex = "Lex",
  RdsDataApiV2 = "RdsDataApiV2",
  Timestream = "Timestream",
}

/**
 * Mapping of AWS features to the regions where they are available from the region they are used
 */
export const AWS_FEATURES_OUT_OF_REGION = {
  [E_AwsFeatures.Evidently]: {
    "ca-central-1": "us-east-1",
    "ap-southeast-2": "us-east-1",
  },
  [E_AwsFeatures.Lex]: {
    "ca-central-1": "us-east-1",
  },
  [E_AwsFeatures.Timestream]: {
    "ca-central-1": "us-east-1",
  },
};

export const AWS_FEATURES_BY_REGION = {
  "eu-west-1": {
    [E_AwsFeatures.Timestream]: true,
    [E_AwsFeatures.Lex]: true,
  },
  "ca-central-1": {
    [E_AwsFeatures.Timestream]: false,
    [E_AwsFeatures.Lex]: false,
  },
  "ap-southeast-2": {
    [E_AwsFeatures.Timestream]: true,
    [E_AwsFeatures.Lex]: true,
  },
};

export function shouldDeployAwsFeature(feature: E_AwsFeatures, region: string): boolean {
  return !Object.keys(AWS_FEATURES_OUT_OF_REGION[feature] || {}).includes(region);
}

export function getAwsFeatureRegion(feature: E_AwsFeatures, region: string): string {
  if (!AWS_FEATURES_OUT_OF_REGION[feature]?.[region]) return region;

  return AWS_FEATURES_OUT_OF_REGION[feature][region];
}

export function isAwsFeatureSupported(feature: E_AwsFeatures, region: string): boolean {
  return !AWS_FEATURES_OUT_OF_REGION[feature]?.[region];
}

export function isManageAppStatisticsSupported(region: string): boolean {
  return isAwsFeatureSupported(E_AwsFeatures.Timestream, region);
}
