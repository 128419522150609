import Bugsnag from "@bugsnag/js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Subscription } from "rxjs";
import { RefreshSink, PushUpdatesService, E_RefreshEvent } from "src/app/shared/services/push-updates.service";
import { filterDefined } from "../../utils/rxjs";
import { CommonService } from "../common.service";

dayjs.extend(utc);

export function Pusher_subscribeToRefreshCache(commonService: CommonService, pushUpdateService: PushUpdatesService): Subscription {
  return pushUpdateService.RefreshSubject.pipe(filterDefined()).subscribe((notification: RefreshSink) => {
    try {
      switch (notification.event) {
        case E_RefreshEvent.Started:
          commonService.isRefreshing = true;
          break;
        case E_RefreshEvent.Progressed:
          break;
        case E_RefreshEvent.Finished:
          commonService.isRefreshing = false;
          break;
        default:
          throw new Error("Unexpected notification type.");
      }

      commonService.lastRefreshTime = dayjs.utc();
      commonService.refreshMessage = notification.message;
    } catch (err) {
      Bugsnag.notify(new Error(err));
    }
  });
}
