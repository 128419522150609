/* eslint-disable import/no-cycle */
import { SHARED } from "src/app/shared/shared";
import { Component, Input } from "@angular/core";
import { DropdownComponent } from "../dropdown.component";

@Component({
  selector: "dentr-dropdown-content",
  templateUrl: "./dropdown-content.component.html",
  host: { class: "hidden" },
  standalone: true,
  imports: [SHARED],
})
export class DropdownContentComponent {
  @Input() anchor: "left" | "right" = "left";
  @Input() width = "64";
  // parent to enforce usage of this component only inside its parent
  constructor(private _dentrDropdown: DropdownComponent) {}
}
