/* eslint-disable import/no-cycle */
import { Component } from "@angular/core";
import { DropdownComponent } from "../dropdown.component";

@Component({
  selector: "dentr-dropdown-trigger",
  templateUrl: "./dropdown-trigger.component.html",
  standalone: true,
})
export class DropdownTriggerComponent {
  // parent to enforce usage of this component only inside its parent
  constructor(private _dentrDropdown: DropdownComponent) {}
}
