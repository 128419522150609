import { Injectable, RendererFactory2 } from "@angular/core";
import { BehaviorSubject, debounceTime, switchMap } from "rxjs";
import { SessionService } from "./session.service";
import { JWTService } from "./jwt.service";

@Injectable({
  providedIn: "root",
})
export class InactivityMonitorService {
  private _lastActivity = new BehaviorSubject<number>(Date.now());

  constructor(private _rendererFactory2: RendererFactory2, private _sessionService: SessionService, private _jwtService: JWTService) {}

  public start(): void {
    const renderer = this._rendererFactory2.createRenderer(null, null);
    const events = [
      "click",
      "keydown",
      "mousemove",
      "touchstart",
      "touchmove",
      "touchend",
      "touchcancel",
      "scroll",
      "wheel",
      "resize",
      "orientationchange",
      "visibilitychange",
      "fullscreenchange",
    ];

    for (const event of events) {
      renderer.listen("document", event, () => {
        this._resetTimer();
      });
    }

    this._lastActivity
      .pipe(
        debounceTime(1000 * 60 * 15), // 15 minutes
        switchMap(() => this._sessionService.logout())
      )
      .subscribe();
  }

  private _resetTimer(): void {
    this._lastActivity.next(Date.now());
  }
}
