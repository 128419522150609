import { Component, OnInit } from "@angular/core";

@Component({
  selector: "dentr-loader",
  templateUrl: "./loader.component.html",
  standalone: true,
})
export class LoaderComponent implements OnInit {
  ngOnInit() {}
}
