import { Component, OnDestroy, OnInit } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AvatarComponent } from "src/app/shared/components/avatar/avatar.component";
import { DevButtonComponent } from "src/app/shared/components/dev-button/dev-button.component";
import { DropdownContentComponent } from "src/app/shared/components/dropdown/dropdown-content/dropdown-content.component";
import { DropdownTriggerComponent } from "src/app/shared/components/dropdown/dropdown-trigger/dropdown-trigger.component";
import { DropdownComponent } from "src/app/shared/components/dropdown/dropdown.component";
import { CommonService } from "src/app/shared/services/common.service";
import { DevService } from "src/app/shared/services/dev.service";
import { JWTService } from "src/app/shared/services/jwt.service";
import { PortalViewerService } from "src/app/shared/services/portal-viewer.service";
import { SaverService } from "src/app/shared/services/saver.service";
import { SaverComponent } from "../saver/saver.component";
import { SubSink } from "subsink";
import { OverlayService } from "src/app/shared/services/overlay.service";
import { PortalViewerModalComponent } from "./portal-viewer-modal/portal-viewer-modal.component";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { heroChatBubbleOvalLeftEllipsis, heroArrowRight, heroArrowLeftOnRectangle, heroDocumentText, heroLifebuoy } from "@ng-icons/heroicons/outline";
import { SHARED } from "src/app/shared/shared";

@Component({
  selector: "dentr-header",
  templateUrl: "./header.component.html",
  standalone: true,
  imports: [
    SHARED,
    AngularSvgIconModule,
    NgIconComponent,
    DevButtonComponent,
    DropdownComponent,
    DropdownTriggerComponent,
    DropdownContentComponent,
    AvatarComponent,
    SaverComponent,
  ],
  providers: [provideIcons({ heroChatBubbleOvalLeftEllipsis, heroLifebuoy, heroArrowRight, heroDocumentText, heroArrowLeftOnRectangle })],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private _subs = new SubSink();
  public intercom_exists: boolean;

  constructor(
    public commonService: CommonService,
    public jwtService: JWTService,
    public devService: DevService,
    public saverService: SaverService,
    public portalViewerService: PortalViewerService,
    private _overlayService: OverlayService
  ) {
    this.intercom_exists = !!window.Intercom;
  }

  public ngOnInit(): void {
    this._subs.sink = this.portalViewerService.onReady.subscribe(() => {
      this._overlayService.open({ component: PortalViewerModalComponent });
    });
  }

  public ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public openIntercom(): void {
    window.Intercom("showNewMessage", "");
  }

  public openHelp(): void {
    window.Intercom("showSpace", "help");
  }
}
