import { Clipboard } from "@angular/cdk/clipboard";
import { SHARED } from "src/app/shared/shared";
import { Component, OnInit } from "@angular/core";
import { OverlayService } from "src/app/shared/services/overlay.service";
import { DevService } from "../../services/dev.service";
import { NotificationService, NotificationTypes } from "../../services/notification.service";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "dev-viewer-modal",
  templateUrl: "./dev-viewer-modal.component.html",
  standalone: true,
  imports: [ModalComponent, SHARED],
})
export class DevViewerModalComponent implements OnInit {
  public manage_url: string;
  public consent_text = "";

  public data: any = {};

  constructor(
    private _overlayService: OverlayService,
    private _devService: DevService,
    private _clipboard: Clipboard,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    const { objectType, object } = this._devService.getObject();
    this.data.objectType = objectType;
    this.data.object = object;
  }

  public copy(): void {
    this._notificationService.open({
      type: NotificationTypes.INFO,
      title: "Copied to clipboard",
    });
    this._clipboard.copy(JSON.stringify(this.data.object));
  }

  public close(): void {
    this._devService.clearObject();
    this._overlayService.close();
  }
}
