import { Subscription } from "rxjs";
import { NotificationService } from "src/app/shared/services/notification.service";
import { E_Event, PushUpdatesService, PracticeSettingsSink } from "src/app/shared/services/push-updates.service";
import Bugsnag from "@bugsnag/js";
import merge from "lodash/merge";
import { CommonService } from "src/app/shared/services/common.service";
import { filterDefined } from "src/app/shared/utils/rxjs";

export function Pusher_subscribeToPracticeSettings(
  pushUpdateService: PushUpdatesService,
  notificationService: NotificationService,
  commonService: CommonService
): Subscription {
  return pushUpdateService.PracticeSettingsSubject.pipe(filterDefined()).subscribe((notification: PracticeSettingsSink) => {
    try {
      const cachedSettings = commonService.practiceSettings;

      switch (notification.event) {
        case E_Event.Updated:
          merge(cachedSettings, notification.item);
          commonService.onPracticeSettingsChanged.next(cachedSettings);
          break;

        default:
          throw new Error("Unexpected notification type.");
      }
    } catch (err) {
      notificationService.reportWarning("Error processing update notification");
      Bugsnag.notify(new Error(err));
    }
  });
}
