<div class="flex justify-evenly" data-e2e-view-portal-modal>
  <dentr-modal #portalModal [gridLayout]="true">
    <div class="flex flex-row justify-between">
      <h2 class="heading-xl">View Portal</h2>
      <button class="button-primary" (click)="close()">Close</button>
    </div>

    <div class="my-10 flex">
      <label
        for="new-patient"
        class="flex basis-1/2 cursor-pointer flex-row items-center rounded-l-md border p-6"
        [ngClass]="{
          'bg-grape-50 border-grape-200': newPatientSelected,
          'border-gray-200': !newPatientSelected
        }"
      >
        <input type="radio" id="new-patient" name="patient-type" [value]="true" [(ngModel)]="newPatientSelected" (ngModelChange)="onRadioChanged($event)" />
        <div class="ml-4">
          <span class="font-medium">As a new patient</span>
          <span class="block font-light" [ngClass]="{ 'text-grape-700': newPatientSelected }">Browse new patient appointments and services</span>
        </div>
      </label>
      <label
        for="existing-patient"
        class="flex basis-1/2 cursor-pointer flex-row items-center rounded-r-md border border-l p-6"
        [ngClass]="{
          'bg-grape-50 border-grape-200': !newPatientSelected,
          'border-gray-200-0': newPatientSelected
        }"
      >
        <input
          type="radio"
          id="existing-patient"
          name="patient-type"
          [value]="false"
          [(ngModel)]="newPatientSelected"
          (ngModelChange)="onRadioChanged($event)"
          data-e2e-as-existing-patient-button
        />
        <div class="ml-4">
          <span class="font-medium">As an existing patient</span>
          <span class="block font-light" [ngClass]="{ 'text-grape-700': !newPatientSelected }"
            >See Portal through the eyes of one of your existing patients</span
          >
        </div>
      </label>
    </div>

    <ng-container>
      <div *ngIf="newPatientSelected">
        <div class="mb-8">
          <h3 class="heading-lg mb-2">Portal landing pages</h3>
          <input type="text" class="w-full" #brandsSearch placeholder="Site or brand name" (keyup)="filterBrands($event)" />
        </div>
        <div #modalScroll>
          <ng-container *ngIf="shownAppliedPracticeBrandCount > 0">
            <h4 class="heading-md text-grape-800 mb-2 uppercase tracking-wide">Brand landing pages</h4>
            <p class="font-normal">Web addresses for sharing groups of practices that share a brand</p>
            <ng-container *ngFor="let item of appliedPracticeBrands">
              <div
                class="selectable-row group"
                *ngIf="!item.hidden"
                [ngClass]="{ active: selectedBrand?.id === item.id && !selectedBrand?.owning_site_id }"
                (click)="onSelectedBrandChanged(item)"
              >
                <div class="selectable-row-details">
                  <ng-icon
                    name="heroCheckCircleSolid"
                    class="text-grape-600 mr-3"
                    size="22"
                    *ngIf="selectedBrand?.id === item.id && !selectedBrand?.owning_site_id"
                  ></ng-icon>
                  <div class="flex grow flex-col">
                    <p class="selectable-row-heading text-base font-semibold text-gray-900">{{ item.display_name }}</p>
                    <p class="text-sm font-normal">{{ item.derived_uri }}</p>
                  </div>
                </div>
                <div class="hidden items-center group-hover:flex">
                  <span
                    (click)="copyToClipboard(item)"
                    [tooltip]="'Copy link'"
                    class="hover:bg-grape-100 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
                  >
                    <ng-icon size="18" name="heroLink" class="text-grape-600"></ng-icon>
                  </span>
                  <span
                    (click)="openInNewTab(item)"
                    [tooltip]="'Launch in new tab'"
                    class="hover:bg-grape-100 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
                  >
                    <ng-icon name="heroArrowRight" size="18" class="text-grape-600"></ng-icon>
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container>
            <ng-container *ngFor="let item of appliedPracticeBrands">
              <div class="mt-8" *ngIf="item.shown_aliases_count > 0">
                <h4 class="heading-md text-grape-800 mb-2 uppercase tracking-wide">{{ item.display_name }}</h4>
                <p class="font-normal">Direct web addresses for marketing individual practices</p>
                <ng-container *ngFor="let site_item of item.applied_at_sites.items">
                  <div
                    *ngIf="!site_item.url_site_alias?.hidden"
                    class="selectable-row group"
                    [ngClass]="{ active: selectedAlias?.alias === site_item.url_site_alias.alias }"
                    (click)="onSelectedBrandAliasChanged(site_item.url_site_alias)"
                  >
                    <div class="selectable-row-details">
                      <ng-icon
                        name="heroCheckCircleSolid"
                        class="text-grape-600 mr-3"
                        *ngIf="selectedAlias?.alias === site_item.url_site_alias?.alias"
                        size="22"
                      ></ng-icon>

                      <div class="flex grow flex-col">
                        <p class="selectable-row-heading text-base font-semibold text-gray-900">
                          {{ getSiteAliasDisplayName(item, site_item) }}
                        </p>
                        <p class="text-sm font-normal">{{ getSiteAliasUri(item, site_item) }}</p>
                      </div>
                    </div>
                    <div class="hidden items-center group-hover:flex">
                      <span
                        (click)="copyToClipboard(item, site_item)"
                        [tooltip]="'Copy link'"
                        class="hover:bg-grape-100 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
                      >
                        <ng-icon name="heroLink" size="18" class="text-grape-600"> </ng-icon>
                      </span>

                      <span
                        (click)="openInNewTab(item, site_item)"
                        [tooltip]="'Launch in new tab'"
                        class="hover:bg-grape-100 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
                      >
                        <ng-icon size="18" name="heroArrowRight" class="text-grape-600"> </ng-icon>
                      </span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="appliedSiteBrands.length > 0 && shownAppliedSiteBrandCount > 0">
            <div class="mt-8">
              <h3 class="text-grape-800 mb-2 font-medium uppercase tracking-wide">Other practice landing pages</h3>
              <p class="font-normal">Web addresses for practices that are not part of a brand</p>
              <ng-container *ngFor="let item of appliedSiteBrands">
                <div
                  class="selectable-row group"
                  *ngIf="!item.hidden"
                  [ngClass]="{ active: selectedBrand?.id === item.id && selectedBrand?.owning_site_id }"
                  (click)="onSelectedBrandChanged(item)"
                >
                  <div class="selectable-row-details">
                    <ng-icon
                      name="heroCheckCircleSolid"
                      class="text-grape-600 mr-3"
                      size="22"
                      *ngIf="selectedBrand?.id === item.id && selectedBrand?.owning_site_id"
                    >
                    </ng-icon>

                    <div class="flex grow flex-col">
                      <p class="selectable-row-heading text-base font-semibold text-gray-900">{{ item.display_name }}</p>
                      <p class="text-sm font-normal">{{ item.derived_uri }}</p>
                    </div>
                  </div>
                  <div class="hidden items-center group-hover:flex">
                    <span
                      (click)="copyToClipboard(item)"
                      [tooltip]="'Copy link'"
                      class="hover:bg-grape-100 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
                    >
                      <ng-icon name="heroLink" size="18" class="text-grape-600"> </ng-icon>
                    </span>

                    <span
                      (click)="openInNewTab(item)"
                      [tooltip]="'Launch in new tab'"
                      class="hover:bg-grape-100 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
                    >
                      <ng-icon size="18" name="heroArrowRight" class="text-grape-600"> </ng-icon>
                    </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <!-- Use hidden instead of ngIf so the modal scroll calculation can be calculated -->
      <div [hidden]="newPatientSelected">
        <h3 class="heading-lg mb-2">Search for a patient</h3>

        <dentr-info-block class="mb-6 mt-4 block text-gray-900" *ngIf="permissionLevel < 5">
          <p>You can only preview patients who are registered as a Portal User.</p>
          <p>This view uses live patient data. Changes you make will be reflected</p>
          <p>on the patient record.</p>
        </dentr-info-block>

        <div *ngIf="commonService.isMultiSite">
          <div class="relative">
            <div class="mb-6">
              <label for="ngSelect">Choose a site</label>
              <dentr-site-switcher (onSiteChanged)="onSiteChanged($event)" [localUpdates]="true" [inverted]="true"></dentr-site-switcher>
            </div>
          </div>
        </div>

        <label class="block" for="query">Enter search terms</label>
        <input
          id="query"
          type="text"
          autocomplete="off"
          name="patient-search"
          class="mb-4 w-full"
          placeholder="Search for a patient by ID, name, date of birth or postcode, etc"
          aria-label="Search for a patient by ID, name, date of birth or postcode, etc"
          (paste)="onInputPaste($event)"
          (keydown)="onInputKeyDown($event)"
          [(ngModel)]="searchCriteria"
          #patientSearch
          data-e2e-patient-search-input
        />

        <div #modalScroll data-e2e-patient-search-results>
          <div *ngIf="portalViewerService.searchingCount > 0" class="flex items-center">
            <dentr-loader class="mr-1.5 block h-3 w-3 text-gray-400"></dentr-loader>
            Searching...
          </div>

          <ng-container *ngIf="portalViewerService.searchResults?.length || 0 > 0">
            <div
              *ngFor="let match of portalViewerService.searchResults; first as isFirst; last as isLast"
              class="relative grid grid-cols-3 items-center rounded-md p-3"
              [ngClass]="{
                'bg-grape-100': match.selected,
                'hover:bg-gray-50': !match.selected && match.registered_portal_user,
                'cursor-pointer': !match.selected && (match.registered_portal_user || permissionLevel === 5),
                'cursor-default': !(!match.selected && (match.registered_portal_user || permissionLevel === 5))
              }"
              (click)="onMatchClicked(match)"
              [attr.data-e2e-patient-search-result]="match.id"
            >
              <div class="col-span-2 flex flex-row items-center" [ngClass]="{ 'opacity-50': !match.registered_portal_user && permissionLevel !== 5 }">
                <dentr-avatar size="sm" [avatar]="match" class="mr-3"></dentr-avatar>
                <div class="flex flex-col">
                  <div class="align-middle">
                    <span class="text-base font-bold">{{ match.first_name }} {{ match.last_name }}</span>
                    <span class="text-grape-600 ml-3 font-bold">{{ match.id }}</span>
                    <span
                      *ngIf="match.registered_portal_user && !match.is_account_locked"
                      class="bg-grape-600 ml-3 rounded-full px-2.5 py-0.5 text-sm text-white"
                      title="{{ match.tooltip }}"
                      >Portal User</span
                    >
                    <span
                      *ngIf="match.registered_portal_user && match.is_account_locked"
                      class="ml-3 inline-flex items-center rounded-full bg-gray-200 px-2.5 py-0.5 text-sm"
                      title="This patient's Portal account is currently locked. Click to unlock now"
                      (click)="unlockPatientAccount($event, match)"
                    >
                      <span *ngIf="unlockingPatientId !== match.id" class="flex items-center">
                        <span>Locked</span>
                        <ng-icon name="heroXMark" size="12" class="ml-1"></ng-icon>
                      </span>

                      <span *ngIf="unlockingPatientId === match.id" class="flex items-center">
                        <span>Unlocking</span>
                        <dentr-loader class="ml-2 block h-2 w-2 text-gray-400"></dentr-loader>
                      </span>
                    </span>
                  </div>
                  <div class="">
                    <span class="text-sm">{{ match.date_of_birth }}</span>
                    <span class="text-grape-600 mx-2 text-sm">&bull;</span>
                    <span class="text-sm"
                      >{{ match.address_line_1 }}<span *ngIf="match.address_line_1 && match.postcode">, </span>{{ match.postcode?.toUpperCase() }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="flex justify-end align-middle text-sm leading-6">
                {{ match.site.name }}
                <div class="ml-3 inline-block" *ngIf="shouldShowSendTaskReminder">
                  <dentr-dropdown>
                    <dentr-dropdown-trigger>
                      <span class="flex cursor-pointer items-center justify-center">
                        <ng-icon name="heroBars3" class="text-gray-500" size="24"></ng-icon>
                      </span>
                    </dentr-dropdown-trigger>
                    <dentr-dropdown-content>
                      <button
                        (click)="sendTaskReminder(match)"
                        class="flex w-full cursor-pointer items-center px-4 py-3 text-sm hover:bg-gray-100"
                        tooltip="Send a task reminder to this patient's mobile number. If the patient has actions due and has not declined SMS messages"
                      >
                        <ng-icon name="heroPaperAirplane" size="22" class="text-grape-500 mr-3"></ng-icon>
                        Send task reminder
                      </button>
                    </dentr-dropdown-content>
                  </dentr-dropdown>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </dentr-modal>
  <div class="z-40 basis-1/4">
    <div class="relative top-10">
      <dentr-devices [device]="DEVICES.IPHONE_14_PRO" [showVideo]="!portalViewerService.portalUrl">
        <iframe
          *ngIf="portalViewerService.portalUrl"
          [src]="portalViewerService.portalUrl"
          title="Test"
          #patientIframe
          data-e2e-patient-impersonation-iframe
        ></iframe>
      </dentr-devices>
    </div>
  </div>
</div>
