import { Component, Input, OnInit } from "@angular/core";
import { JWTService } from "src/app/shared/services/jwt.service";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { CommonService } from "src/app/shared/services/common.service";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { Constants } from "src/constants";
import { DevService } from "src/app/shared/services/dev.service";
import { SHARED } from "src/app/shared/shared";
import { RefreshCacheComponent } from "../refresh-cache/refresh-cache.component";
import { SiteSwitcherComponent } from "src/app/shared/components/site-switcher/site-switcher.component";
import { ACCORDION_ANIMATION } from "src/app/shared/utils/animations";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import {
  heroSparkles,
  heroUser,
  heroChevronRight,
  heroCalendar,
  heroPencil,
  heroCreditCard,
  heroBell,
  heroSwatch,
  heroDeviceTablet,
  heroWrench,
} from "@ng-icons/heroicons/outline";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FeatureFlagsService } from "src/app/shared/services/feature-flags.service";
import { SupportToolsService } from "src/app/shared/services/support-tools.service";

// Keep these as camelCase as they are used for interpolation in the template
export enum E_NavItemId {
  ONLINE_BOOKING = "onlineBooking",
  ONLINE_SIGNING = "onlineSigning",
  PATIENT_DETAILS = "patientDetails",
  PAYMENTS = "payments",
  DEVICES = "devices",
  NOTIFICATIONS = "notifications",
  CUSTOMISE = "customise",
  DEV_MODE = "devMode",
  SUPPORT_TOOLS = "supportTools",
}

export interface I_NavChildItem {
  title: string;
  route: string;
  unitTestSelector: string;
  e2eTestSelector: string;
  show?: boolean;
}

export interface I_NavItem {
  id: E_NavItemId;
  title: string;
  route: string;
  icon: string;
  subItems?: Array<I_NavChildItem>;
  dropdown?: T_Dropdowns;
  unitTestSelector: string;
  e2eTestSelector: string;
  show?: boolean;
}

export type T_Dropdowns =
  | "onlineBooking"
  | "customise"
  | "onlineSigning"
  | "devices"
  | "payments"
  | "devMode"
  | "notifications"
  | "patientDetails"
  | "supportTools";

@Component({
  selector: "dentr-navbar",
  templateUrl: "./navbar.component.html",
  host: { class: "fixed top-0 bottom-0 left-0 border-r w-72 flex flex-col justify-between bg-gray-800 text-gray-300" },
  standalone: true,
  imports: [RouterModule, SHARED, NgIconComponent, RefreshCacheComponent, SiteSwitcherComponent, AngularSvgIconModule],
  animations: [ACCORDION_ANIMATION],
  providers: [
    provideIcons({ heroSparkles, heroUser, heroChevronRight, heroCalendar, heroDeviceTablet, heroWrench, heroPencil, heroCreditCard, heroBell, heroSwatch }),
  ],
})
export class NavbarComponent implements OnInit {
  @Input() practiceName: string;
  public site_url = window.location.origin;
  public admin_url: string;
  public navItems = Array<I_NavItem>();
  public activeRoute = "";
  public dropdowns: Record<T_Dropdowns, boolean> = {
    onlineBooking: false,
    customise: false,
    onlineSigning: false,
    devices: false,
    payments: false,
    devMode: false,
    notifications: false,
    patientDetails: false,
    supportTools: false,
  };
  public CONSTANTS: typeof Constants;
  public customiseDefaultRoute: string;

  constructor(
    public commonService: CommonService,
    public featureFlagsService: FeatureFlagsService,
    public jwtService: JWTService,
    public devService: DevService,
    private _router: Router,
    private _supportToolsService: SupportToolsService,
    private _navigationService: NavigationService
  ) {
    this.admin_url = this.jwtService.getJWT().admin_url;
    this.CONSTANTS = Constants;
  }

  public toggleDropdown(dropdown: T_Dropdowns): void {
    this.dropdowns[dropdown] = !this.dropdowns[dropdown];
  }

  private _createNavItem(
    id: E_NavItemId,
    title: string,
    route: string,
    icon: string,
    dropdown: T_Dropdowns,
    testSelector: string,
    subItems = new Array<I_NavChildItem>(),
    show = true
  ): I_NavItem {
    return {
      id,
      title,
      route,
      icon,
      dropdown,
      e2eTestSelector: testSelector,
      unitTestSelector: testSelector,
      subItems: subItems.filter((item) => item.show),
      show,
    };
  }

  private _createSubItem(title: string, route: string, testSelector: string, show = true): I_NavChildItem {
    return {
      title,
      route,
      e2eTestSelector: testSelector,
      unitTestSelector: testSelector,
      show,
    };
  }

  private _generateNavItems(): void {
    this._generateDefaultRoutes();

    this.navItems = [
      this._createNavItem(
        E_NavItemId.ONLINE_BOOKING,
        "Online Booking",
        Constants.ROUTE_ONLINE_BOOKING_APPOINTMENTS,
        "heroCalendar",
        "onlineBooking",
        "online-booking",
        [
          this._createSubItem("Appointments", Constants.ROUTE_ONLINE_BOOKING_APPOINTMENTS, "online-booking-appointments"),
          this._createSubItem("Plans & pricing", Constants.ROUTE_ONLINE_BOOKING_PLANS, "online-booking-plans-and-pricing", this.commonService.feat_Plans),
          this._createSubItem("Settings", Constants.ROUTE_ONLINE_BOOKING_SETTINGS, "online-booking-settings"),
        ]
      ),
      this._createNavItem(
        E_NavItemId.ONLINE_SIGNING,
        "Online Signing",
        Constants.ROUTE_ONLINE_SIGNING_DOCUMENT_TYPES,
        "heroPencil",
        "onlineSigning",
        "online-signing",
        [
          this._createSubItem("Document types", Constants.ROUTE_ONLINE_SIGNING_DOCUMENT_TYPES, "online-signing-document-types"),
          this._createSubItem("Settings", Constants.ROUTE_ONLINE_SIGNING_SETTINGS, "online-signing-settings", this.commonService.isL4OrHigher),
        ]
      ),
      this._createNavItem(
        E_NavItemId.PATIENT_DETAILS,
        "Patient Details",
        Constants.ROUTE_PATIENT_DETAILS_DATA,
        "heroUser",
        "patientDetails",
        "patient-profile",
        [this._createSubItem("Types of data", Constants.ROUTE_PATIENT_DETAILS_DATA, "patient-profile-data")]
      ),
      this._createNavItem(
        E_NavItemId.PAYMENTS,
        "Payments",
        Constants.ROUTE_PAYMENTS_TYPES,
        "heroCreditCard",
        "payments",
        "payments",
        [
          this._createSubItem("Payment types", Constants.ROUTE_PAYMENTS_TYPES, "payments-payment-types"),
          this._createSubItem(
            "Link with Stripe",
            Constants.ROUTE_PAYMENTS_LINK_WITH_PAYMENT_PROVIDER,
            "payments-link-with-payment-provider",
            this.commonService.isL4OrHigher
          ),
          this._createSubItem("Settings", Constants.ROUTE_PAYMENTS_SETTINGS, "payments-settings", this.commonService.isL4OrHigher),
        ],
        this.commonService.feat_Payments
      ),
      this._createNavItem(
        E_NavItemId.DEVICES,
        "Devices",
        Constants.ROUTE_DEVICES_PAIR,
        "heroDeviceTablet",
        "devices",
        "devices",
        [
          this._createSubItem("Pair", Constants.ROUTE_DEVICES_PAIR, "devices-link"),
          this._createSubItem("Settings", Constants.ROUTE_DEVICES_SETTINGS, "devices-settings", this.commonService.isL4OrHigher),
        ],
        this.commonService.feat_Devices
      ),
      this._createNavItem(
        E_NavItemId.NOTIFICATIONS,
        "Patient Notifications",
        Constants.ROUTE_PATIENT_NOTIFICATION_TYPES,
        "heroBell",
        "notifications",
        "patient-notifications",
        [
          this._createSubItem("Notification types", Constants.ROUTE_PATIENT_NOTIFICATION_TYPES, "patient-notifications-types"),
          this._createSubItem("Settings", Constants.ROUTE_PATIENT_NOTIFICATION_SETTINGS, "patient-notifications-settings"),
        ],
        this.commonService.isL4OrHigher
      ),
      this._createNavItem(
        E_NavItemId.CUSTOMISE,
        "Customise",
        this.customiseDefaultRoute,
        "heroSwatch",
        "customise",
        "customise",
        [
          this._createSubItem("Brands", Constants.ROUTE_CUSTOMISE_BRANDS, "customise-brands", this.commonService.feat_CustomiseBrands),
          this._createSubItem("Portal", Constants.ROUTE_CUSTOMISE_PORTAL, "customise-portal", this.commonService.feat_CustomisePortal),
          this._createSubItem(
            "Practitioners",
            Constants.ROUTE_CUSTOMISE_PRACTITIONERS,
            "customise-practitioners",
            this.commonService.feat_CustomisePractitioners
          ),
          this._createSubItem("Settings", Constants.ROUTE_CUSTOMISE_SETTINGS, "customise-settings", this.commonService.isL4OrHigher),
        ],
        this.customiseFeaturesEnabled
      ),
      this._createNavItem(
        E_NavItemId.DEV_MODE,
        "Dev / Sparkle Mode",
        Constants.ROUTE_DEV_MODE_BULK_DATA,
        "heroSparkles",
        "devMode",
        "dev-mode",
        [
          this._createSubItem("Bulk data", Constants.ROUTE_DEV_MODE_BULK_DATA, "dev-mode-bulk-data"),
          this._createSubItem("Diagnostics", Constants.ROUTE_DEV_MODE_DIAGNOSTICS, "dev-mode-diagnostics"),
          this._createSubItem("Links", Constants.ROUTE_DEV_MODE_LINKS, "dev-mode-links"),
        ],
        this.devService.isUserDev
      ),
      this._createNavItem(
        E_NavItemId.SUPPORT_TOOLS,
        "Support Tools",
        Constants.ROUTE_SUPPORT_TOOLS_CLEAR_API_CACHE,
        "heroWrench",
        "supportTools",
        "support-tools",
        [
          this._createSubItem("Clear API cache", Constants.ROUTE_SUPPORT_TOOLS_CLEAR_API_CACHE, "support-tools-clear-cache"),
          this._createSubItem("Query builder", Constants.ROUTE_SUPPORT_TOOLS_QUERY_BUILDER, "support-tools-query-builder"),
        ],
        this._supportToolsService.showSupportTools
      ),
    ];
  }

  public get customiseFeaturesEnabled(): boolean {
    return this.commonService.feat_CustomiseBrands || this.commonService.feat_CustomisePortal || this.commonService.feat_CustomisePractitioners;
  }

  public navigateTo(url) {
    this._navigationService.navigate(url);
  }

  private _generateDefaultRoutes() {
    switch (true) {
      case this.commonService.features.branding:
        if (this.commonService.isMultiSite) {
          this.customiseDefaultRoute = this.CONSTANTS.ROUTE_CUSTOMISE_BRANDS;
        } else {
          this.customiseDefaultRoute = this.CONSTANTS.ROUTE_CUSTOMISE_PORTAL;
        }
        break;
      case this.commonService.features.practitioner_profiles:
        this.customiseDefaultRoute = this.CONSTANTS.ROUTE_CUSTOMISE_PRACTITIONERS;
        break;
      default:
        // Fallback
        this._navigationService.navigate(this.CONSTANTS.ROUTE_ONLINE_BOOKING_APPOINTMENTS);
    }
  }

  private _handleRoute(route: string): void {
    for (const [key, _value] of Object.entries(this.dropdowns)) {
      const new_route = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
      this.dropdowns[key] = route.includes(new_route);
    }
    this.activeRoute = route;
  }

  public ngOnInit(): void {
    this._generateNavItems();
    this._handleRoute(this._router.url);
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._handleRoute(event.url || event.urlAfterRedirects);
      }
    });
  }
}
