import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, EmbeddedViewRef, HostListener, Injector, Input } from "@angular/core";
import { TooltipComponent } from "./tooltip.component";

export enum TooltipPosition {
  ABOVE = "above",
  BELOW = "below",
  LEFT = "left",
  RIGHT = "right",
  DYNAMIC = "dynamic",
  DEFAULT = "above",
}

export enum TooltipTheme {
  DARK = "dark",
  LIGHT = "light",
  DEFAULT = "dark",
}

@Directive({
  selector: "[tooltip]",
  standalone: true,
})
export class TooltipDirective {
  @Input() tooltip = "";
  @Input() position: TooltipPosition = TooltipPosition.DEFAULT;
  @Input() theme: TooltipTheme = TooltipTheme.DEFAULT;
  @Input() showDelay = 0;
  @Input() hideDelay = 0;

  private _componentRef: ComponentRef<any> | null = null;
  private _showTimeout?: number;
  private _hideTimeout?: number;
  private _touchTimeout?: number;

  constructor(
    private _elementRef: ElementRef,
    private _appRef: ApplicationRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector
  ) {}

  @HostListener("mouseenter")
  onMouseEnter(): void {
    this._initializeTooltip();
  }

  @HostListener("mouseleave")
  onMouseLeave(): void {
    this._setHideTooltipTimeout();
  }

  @HostListener("mousemove", ["$event"])
  onMouseMove($event: MouseEvent): void {
    if (this._componentRef !== null && this.position === TooltipPosition.DYNAMIC) {
      this._componentRef.instance.left = $event.clientX;
      this._componentRef.instance.top = $event.clientY;
      this._componentRef.instance.tooltip = this.tooltip;
    }
  }

  @HostListener("touchstart", ["$event"])
  onTouchStart($event: TouchEvent): void {
    $event.preventDefault();
    window.clearTimeout(this._touchTimeout);
    this._touchTimeout = window.setTimeout(this._initializeTooltip.bind(this), 500);
  }

  @HostListener("touchend")
  onTouchEnd(): void {
    window.clearTimeout(this._touchTimeout);
    this._setHideTooltipTimeout();
  }

  private _initializeTooltip() {
    if (this._componentRef === null) {
      window.clearInterval(this.hideDelay);
      const componentFactory = this._componentFactoryResolver.resolveComponentFactory(TooltipComponent);
      this._componentRef = componentFactory.create(this._injector);

      this._appRef.attachView(this._componentRef.hostView);
      const [tooltipDOMElement] = (this._componentRef.hostView as EmbeddedViewRef<any>).rootNodes;

      this._setTooltipComponentProperties();

      document.body.appendChild(tooltipDOMElement);
      this._showTimeout = window.setTimeout(this._showTooltip.bind(this), this.showDelay);
    }
  }

  private _setTooltipComponentProperties() {
    if (this._componentRef !== null) {
      this._componentRef.instance.tooltip = this.tooltip;
      this._componentRef.instance.position = this.position;
      this._componentRef.instance.theme = this.theme;

      const { left, right, top, bottom } = (this._elementRef.nativeElement as HTMLElement).getBoundingClientRect();

      switch (this.position) {
        case TooltipPosition.BELOW: {
          this._componentRef.instance.left = Math.round((right - left) / 2 + left);
          this._componentRef.instance.top = Math.round(bottom);
          break;
        }
        case TooltipPosition.ABOVE: {
          this._componentRef.instance.left = Math.round((right - left) / 2 + left);
          this._componentRef.instance.top = Math.round(top);
          break;
        }
        case TooltipPosition.RIGHT: {
          this._componentRef.instance.left = Math.round(right);
          this._componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        case TooltipPosition.LEFT: {
          this._componentRef.instance.left = Math.round(left);
          this._componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  private _showTooltip() {
    if (this._componentRef !== null) {
      this._componentRef.instance.visible = true;
    }
  }

  private _setHideTooltipTimeout() {
    this._hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this._componentRef !== null) {
      window.clearInterval(this._showTimeout);
      window.clearInterval(this.hideDelay);
      this._appRef.detachView(this._componentRef.hostView);
      this._componentRef.destroy();
      this._componentRef = null;
    }
  }
}
