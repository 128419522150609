import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  public getItem(key: string): any {
    return window.localStorage.getItem(key);
  }

  public setItem(key: string, data: any): void {
    window.localStorage.setItem(key, data);
  }

  public removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }
}
