import { Component } from "@angular/core";

@Component({
  selector: "dentr-loader-overlay",
  templateUrl: "./loader-overlay.component.html",
  host: {
    class: "absolute w-screen h-screen top-0 left-0 right-0 bottom-0 z-50",
  },
})
export class LoaderOverlayComponent {}
