import forOwn from "lodash/forOwn";
import merge from "lodash/merge";
import { Subject } from "rxjs";
import { LoggedInUserSettingsBase } from "../../../../../backend/src/graph/logged_in_user_settings/logged-in-user-settings-base";

export class LoggedInUserSettingsEntry extends LoggedInUserSettingsBase {
  public static onMerge = new Subject<LoggedInUserSettingsEntry>();

  constructor(baseItem?: LoggedInUserSettingsBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }

  public merge(item: LoggedInUserSettingsEntry) {
    merge(this, item);
    LoggedInUserSettingsEntry.onMerge.next(this);
  }
}
