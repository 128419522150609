import { ChangeDetectorRef, Component, HostListener } from "@angular/core";
import dayjs from "dayjs";
import { Enums } from "src/enums";
import { CommonService } from "./shared/services/common.service";
import { DevService } from "./shared/services/dev.service";
import { JWTService } from "./shared/services/jwt.service";
import { PushUpdatesService } from "./shared/services/push-updates.service";
import { initialise_intercom } from "./shared/third_party/intercom";
import { MainComponent } from "./main/main.component";
import { NotificationComponent } from "./shared/components/notification/notification.component";
import { ImpersonationComponent } from "./shared/components/impersonation/impersonation.component";
import { HttpClientModule } from "@angular/common/http";
import { OverlayService } from "./shared/services/overlay.service";
import { EnvService } from "./shared/services/env.service";
import { SubSink } from "subsink";
import { ElevatePasswordComponent } from "./main/components/elevate-password/elevate-password.component";
import { NotificationInstance, NotificationService, NotificationTypes } from "./shared/services/notification.service";
import { timer } from "rxjs";
import { filter } from "rxjs/operators";
import { FADE_IN_ANIMATION, LEFT_TO_RIGHT_ANIMATION } from "./shared/utils/animations";
import { SHARED } from "./shared/shared";
import { SessionService } from "./shared/services/session.service";
import { SantaSleighComponent } from "./shared/components/santa-sleigh/santa-sleigh.component";
import { LocalStorageService } from "./shared/services/local-storage.service";
import { Constants } from "src/constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [SHARED, MainComponent, NotificationComponent, ImpersonationComponent, HttpClientModule, SantaSleighComponent],
  animations: [FADE_IN_ANIMATION, LEFT_TO_RIGHT_ANIMATION],
})
export class AppComponent {
  private _typed = "";
  private _lastTyped = dayjs();

  public showSantaSleigh: boolean;
  private _subs = new SubSink();
  public is_ready = false;
  public DEVICE_TYPE = Enums.DEVICES;
  public isOverlayOpen: boolean;
  constructor(
    private _pushUpdateService: PushUpdatesService,
    private _envService: EnvService,
    private _overlayService: OverlayService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationService: NotificationService,
    private _sessionService: SessionService,
    private _localStorageService: LocalStorageService,
    public jwtService: JWTService,
    public devService: DevService,
    public commonService: CommonService
  ) {}

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler() {
    if (this.isOverlayOpen) this._overlayService.close();
  }
  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.jwtService?.getJWT()?.permission_level === 5) return;

    if (dayjs().diff(this._lastTyped, "seconds") > 5) this._typed = "";

    if (event.key === "Enter") {
      if (this._typed === "elevate me") {
        this._overlayService.open({ component: ElevatePasswordComponent });
      }
      this._typed = "";
      return;
    }

    this._typed += event.key;
    this._lastTyped = dayjs();
  }

  public closeOverlay(): void {
    this._overlayService.close();
  }

  async ngOnInit() {
    this.showSantaSleigh = this.showSantaSleigh =
      this._localStorageService.getItem(Constants.SANTA_SLEIGH_STORAGE_NAME) !== "true" &&
      this.commonService.enableWinter &&
      this.devService.isUserImpersonating;

    if (!this.jwtService.jwt) await this._sessionService.logout();
    this._subs.sink = this._overlayService.onOverlayChange.subscribe((is_open) => {
      this.isOverlayOpen = is_open;
      this._changeDetectorRef.detectChanges();
    });
    const jwt = this.jwtService.getJWT();
    this._pushUpdateService.connect(jwt.practice_id, jwt.jwtString);

    this._subs.sink = this._pushUpdateService.DownForMaintenanceSubject.pipe(filter((data) => data.status === "down")).subscribe((data) => {
      this._notificationService.open(
        new NotificationInstance({
          title: "Maintenance advance notice",
          body: `Portal will go down for maintenance at ${dayjs()
            .add(data.delay / 1000, "seconds")
            .format("h:mm a")}`,
          type: NotificationTypes.INFO,
          timeout: data.delay,
        })
      );

      this._subs.sink = timer(data.delay).subscribe(() => {
        window.location.reload();
      });
    });

    this.commonService.onPracticeDataReady.subscribe((is_ready) => {
      this.is_ready = is_ready;

      if (is_ready) {
        const { user } = this.commonService;
        const interComUser = {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          created_at: dayjs(user.created_at).unix(),
          user_hash: user.intercom_hash,
          user_id: this.jwtService.getJWT().user_id,
        };

        initialise_intercom(interComUser, this._envService.env.INTERCOM_APP_ID);
      }
    });
  }

  public onLeftToRightAnimateStart(): void {
    this._localStorageService.setItem(Constants.SANTA_SLEIGH_STORAGE_NAME, true);
  }

  public onLeftToRightAnimateDone(): void {
    this.showSantaSleigh = false;
  }
}
