import { E_Timestream_Ranges } from "@backend/graph/_common/types";
import { PracticeEntry } from "src/app/data_model/practice";

export enum E_Stats_Types {
  ONLINE_SIGNING = "ONLINE_SIGNING",
  APPOINTMENTS = "APPOINTMENTS",
  CANCELLATIONS = "CANCELLATIONS",
}

export enum E_Stats_Names {
  ONLINE_SIGNING = "online_signing_statistics",
  APPOINTMENTS = "appointments_statistics",
  CANCELLATIONS = "cancellation_statistics",
}

export class GraphQuery {
  public practice: PracticeEntry;
}

export const STATISTIC_TYPES = (type: E_Stats_Types, range: E_Timestream_Ranges, site_id?: string, dataPoint?: string) => {
  const statName = E_Stats_Names[type];
  if (!statName) throw new Error(`Unknown stat name for type ${type}`);

  if (site_id) {
    return `{
    practice {
      site(site_id: "${site_id}") {
        ${statName}(range: ${range}) {
          data {
            measurement
            label
            count
            ${dataPoint}
          }
        }
      }
    }
  }`;
  }

  return `{
    practice {
      ${statName}(range: ${range}) {
        data {
          measurement
          label
          count
          ${dataPoint}
        }
      }
    }
  }`;
};
