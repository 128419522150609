<div
  [ngClass]="{
    'right-0 h-9 w-full rounded-none': devService.isImpersonationVisible,
    'bottom-1 left-auto right-1 h-8 w-8 cursor-pointer rounded-full opacity-50 hover:opacity-100': !devService.isImpersonationVisible
  }"
  class="bg-grape-700 fixed bottom-0 z-50 px-4 text-white transition-all duration-200 ease-out"
>
  <div
    class="absolute right-1/2 top-1/2 flex -translate-y-1/2 translate-x-1/2 items-center justify-center transition-all ease-out"
    [ngClass]="{
      'pointer-events-none opacity-0 duration-75': devService.isImpersonationVisible,
      'opacity-100 delay-200': !devService.isImpersonationVisible
    }"
    (click)="toggleBanner($event)"
  >
    <ng-icon name="heroEye" size="20"></ng-icon>
  </div>
  <div
    [ngClass]="{
      'pointer-events-none opacity-0 duration-75': !devService.isImpersonationVisible,
      'opacity-100 delay-200 ': devService.isImpersonationVisible
    }"
    class="flex h-full items-center justify-evenly font-normal transition-all ease-out"
  >
    <div>
      <p class="">
        Impersonating <span class="font-bold">{{ user_name }}</span> at <span class="font-bold">{{ practice_name }}</span>
      </p>
    </div>
    <div class="flex items-center space-x-6">
      <span class="text-white underline" role="button" tabIndex="0" (click)="goToTools($event)">Tools</span>
      <span class="text-white underline" role="button" tabIndex="0" (click)="toggleBanner($event)">Hide Banner</span>
      <span class="text-white underline" role="button" tabIndex="0" (click)="devService.isDiagnosticsVisible = !devService.isDiagnosticsVisible">
        <span *ngIf="devService.isDiagnosticsVisible">Hide</span>
        <span *ngIf="!devService.isDiagnosticsVisible">Show</span> Diagnostics
      </span>
      <span class="text-white underline" role="button" tabIndex="0" (click)="logout($event)">End impersonation</span>
    </div>
  </div>
</div>
