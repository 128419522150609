import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { BrandEntry } from "src/app/data_model/brands";
import { PatientEntry } from "src/app/data_model/patient";
import { LoaderOverlayComponent } from "../components/loader/loader-overlay/loader-overlay.component";
import { CommonService } from "./common.service";
import { HttpService } from "./http.service";
import { OverlayService } from "./overlay.service";
import * as palette from "palette";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { FeatureUpgradeModalComponent } from "../components/feature-upgrade/feature-upgrade-modal/feature-upgrade-modal.component";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import locale from "dayjs/locale/en-gb";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.locale(locale);
dayjs.extend(localizedFormat);
@Injectable({
  providedIn: "root",
})
export class PortalViewerService {
  private _appliedBrands: Array<BrandEntry> | null;
  public get appliedBrands(): Array<BrandEntry> {
    return this._appliedBrands || [];
  }

  private _searchResults = new Array<PatientEntry>();
  public get searchResults() {
    return this._searchResults;
  }

  private _searchingCount = 0;
  public get searchingCount() {
    return this._searchingCount;
  }

  private _unsafePortalUrl: string | null = null;
  private _portalUrl: SafeResourceUrl | null;
  public get portalUrl(): any {
    return this._portalUrl;
  }

  public onReady = new Subject<void>();

  public constructor(
    private _httpService: HttpService,
    private _overlayService: OverlayService,
    private _commonService: CommonService,
    private _sanitizer: DomSanitizer
  ) {}

  // #region Common
  private _logoutPatient(patientIframe: HTMLIFrameElement): void {
    if (!patientIframe.contentWindow || !this._unsafePortalUrl) return;
    patientIframe.contentWindow.postMessage("signout", this._unsafePortalUrl);
  }

  public close(patientIframe: HTMLIFrameElement) {
    this._logoutPatient(patientIframe);

    this._appliedBrands = null;
    this._searchResults.length = 0;
    this._searchingCount = 0;
    this._unsafePortalUrl = null;
    this._portalUrl = null;
  }

  // #endregion

  // #region New Patients
  public async preparePortalViewer() {
    if (this._commonService.isRestrictedMode) {
      this._overlayService.open({
        component: FeatureUpgradeModalComponent,
      });
      return;
    }
    const QUERY = `
    {
      practice {
        all_applied_brands {
          items {
            id
            owning_site_id
            display_name
            used_custom_domain {
              custom_domain_id
              custom_domain {
                domain
                id
              }
            }
            used_default_domain {
              default_domain_id
              default_domain {
                domain
                id
              }
              subdomain
            }
            applied_at_sites {
              items {
                id
                name
                nickname
                url_site_alias {
                  alias
                }
              }
            }
          }
        }
      }
    }`;

    this._overlayService.open({
      component: LoaderOverlayComponent,
    });

    const applied_brands = (await this._httpService.fetchData<any>(QUERY)).practice.all_applied_brands.items;

    const applied_brand_entries = new Array<BrandEntry>();
    for (const brand of applied_brands) {
      const wrappedBrand = new BrandEntry(brand);
      applied_brand_entries.push(wrappedBrand);

      for (const site of wrappedBrand.applied_at_sites.items) {
        site.url_site_alias.owning_brand = wrappedBrand;
      }
    }

    this._overlayService.close();
    this._appliedBrands = applied_brand_entries;

    if (applied_brand_entries.length >= 1) this.onReady.next();
  }

  public impersonateNewPatient(brandUrl: string, patientIFrame: HTMLIFrameElement) {
    if (patientIFrame) this._logoutPatient(patientIFrame);

    this._unsafePortalUrl = `https://${brandUrl}`;
    this._portalUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this._unsafePortalUrl);
  }
  // #endregion

  // #region Existing Patients

  private _getColors(): Array<string> {
    // We dont need the Tailwind green colour for the practitioner avatars - currently it won't be recognised so will come out as white
    return Object.keys(palette).filter((color) => color !== "green");
  }

  public async impersonateExistingPatient(patient: PatientEntry, patientIFrame: HTMLIFrameElement) {
    if (patientIFrame) this._logoutPatient(patientIFrame);

    this._portalUrl = null;

    for (const p of this.searchResults)
      if (p.selected) {
        p.selected = false;
        break;
      }
    patient.selected = true;

    const query = `
      mutation {
        beginPatientImpersonation(new_item: { patient_id: "${patient.id}"}) {
          patient_portal_url
        }
      }
    `;

    const response = await this._httpService.fetchData<any>(query);
    this._unsafePortalUrl = response.beginPatientImpersonation.patient_portal_url;
    this._portalUrl = this._unsafePortalUrl && this._sanitizer.bypassSecurityTrustResourceUrl(this._unsafePortalUrl);
  }

  public async search(searchCriteria: string, siteId = "") {
    this._searchingCount += 1;

    const query = `{
      find_patients(search_criteria: "${searchCriteria}" ${siteId === "" ? "" : `, site_id: "${siteId}"`}) {
        items {
          first_name
          last_name
          id
          date_of_birth
          address_line_1
          postcode
          last_impersonator_login
          last_patient_login
          registered_portal_user
          is_account_locked
          site {
            name
          }
        }
      }
    }`;

    const response = await this._httpService.fetchData<any>(query);
    if (response) {
      const previousLocale = dayjs.locale();

      this._searchResults.length = 0;
      let allColors: Array<string> = [];

      try {
        // Set the locale to the practice locale
        dayjs.locale(this._commonService.locale_code);

        for (const patient of response.find_patients.items) {
          patient.selected = false;
          patient.date_of_birth = dayjs(patient.date_of_birth).format("L");

          if (patient.last_patient_login) {
            patient.tooltip = `This patient last used portal ${dayjs.unix(patient.last_patient_login).fromNow(true)} ago`;
          } else {
            patient.tooltip = "We currently don't know when this patient last used portal";
          }

          if (patient.last_impersonator_login) {
            patient.tooltip += "\n\n" + `This patient was last impersonated ${dayjs.unix(patient.last_impersonator_login).fromNow(true)} ago`;
          } else {
            patient.tooltip += "\n\n" + "We currently don't know when this patient was last impersonated";
          }

          if (allColors.length === 0) allColors = this._getColors();
          const color = allColors.shift();
          patient.bg_class = `bg-${color}-200`;
          patient.text_class = `text-${color}-700`;
        }
      } catch (err) {
        console.error(err);
      } finally {
        // Ensure we set it back to whatever it was before to avoid messing anything up
        dayjs.locale(previousLocale);
      }
      this._searchResults.push(...response.find_patients.items);
      this._searchingCount -= 1;
    }
  }

  public async unlockPatientAccount(patient: PatientEntry): Promise<void> {
    const query = `
      mutation {
        unlockPatientAccount(patient_id: "${patient.id}")
      }
    `;

    await this._httpService.fetchData<any>(query);
  }
  // #endregion

  public async sendTaskReminderNotification(patient: PatientEntry): Promise<boolean> {
    const query = `
      mutation {
        sendTaskReminderNotificationForPatient(patient_id: "${patient.id}")
      }
    `;
    const response = await this._httpService.fetchData<any>(query);

    return response.sendTaskReminderNotificationForPatient;
  }
}
