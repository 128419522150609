import { Injectable } from "@angular/core";

export enum SaverStatuses {
  ERROR = "ERROR",
  SAVING = "SAVING",
  SAVED = "SAVED",
}

@Injectable({
  providedIn: "root",
})
export class SaverService {
  private _saverStatus: SaverStatuses | null = null;
  private _timeout: NodeJS.Timeout | null = null;
  public get saverStatus(): SaverStatuses | null {
    return this._saverStatus;
  }

  public set saverStatus(value: SaverStatuses | null) {
    if (this._timeout) clearTimeout(this._timeout);
    this._saverStatus = value;
    this._timeout = setTimeout(() => {
      this.saverStatus = null;
    }, 5000);
  }
}
