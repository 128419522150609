import { NotificationService, NotificationTypes } from "src/app/shared/services/notification.service";
import { JWTService } from "src/app/shared/services/jwt.service";
import { OverlayService } from "src/app/shared/services/overlay.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { SHARED } from "src/app/shared/shared";
import { Enums } from "src/enums";
import { PatientEntry } from "src/app/data_model/patient";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";
import { AvatarComponent } from "src/app/shared/components/avatar/avatar.component";
import { DevButtonComponent } from "src/app/shared/components/dev-button/dev-button.component";
import { DevicesComponent } from "src/app/shared/components/devices/devices.component";
import { LoaderComponent } from "src/app/shared/components/loader/loader.component";
import { PageHeadingComponent } from "src/app/shared/components/page-heading/page-heading.component";
import { NgSelectComponent, NgSelectModule } from "@ng-select/ng-select";
import { PortalViewerService } from "src/app/shared/services/portal-viewer.service";
import { FormsModule } from "@angular/forms";
import { BrandEntry } from "src/app/data_model/brands";
import { SiteSwitcherComponent } from "src/app/shared/components/site-switcher/site-switcher.component";
import { CommonService } from "src/app/shared/services/common.service";
import cloneDeep from "lodash/cloneDeep";
import { SiteEntry } from "src/app/data_model/site";
import { InfoBlockComponent } from "src/app/shared/components/info-block/info-block.component";
import { TooltipDirective } from "src/app/shared/components/tooltip/tooltip.directive";
import { UrlSiteAliasEntry } from "src/app/data_model/url-site-alias";
import { FeatureFlagsService } from "src/app/shared/services/feature-flags.service";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { heroXMark, heroLink, heroArrowRight, heroBars3, heroPaperAirplane } from "@ng-icons/heroicons/outline";
import { heroCheckCircleSolid } from "@ng-icons/heroicons/solid";
import Bugsnag from "@bugsnag/js";
import { DevService } from "src/app/shared/services/dev.service";
import { DropdownComponent } from "src/app/shared/components/dropdown/dropdown.component";
import { DropdownContentComponent } from "src/app/shared/components/dropdown/dropdown-content/dropdown-content.component";
import { DropdownTriggerComponent } from "src/app/shared/components/dropdown/dropdown-trigger/dropdown-trigger.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "dentr-portal-viewer-modal",
  templateUrl: "./portal-viewer-modal.component.html",
  standalone: true,
  imports: [
    ModalComponent,
    NgIconComponent,
    SHARED,
    DevicesComponent,
    PageHeadingComponent,
    DevButtonComponent,
    AvatarComponent,
    LoaderComponent,
    NgSelectModule,
    FormsModule,
    SiteSwitcherComponent,
    InfoBlockComponent,
    TooltipDirective,
    DropdownComponent,
    DropdownContentComponent,
    DropdownTriggerComponent,
  ],
  providers: [provideIcons({ heroXMark, heroCheckCircleSolid, heroLink, heroArrowRight, heroBars3, heroPaperAirplane })],
})
export class PortalViewerModalComponent implements OnInit, AfterViewInit {
  @ViewChild("portalModal") portalModal: ModalComponent;
  private readonly _REGEX = /^[^\x22\x27\x2f\x5c\x60]*$/;
  public newPatientSelected = true;
  public selectedBrand: BrandEntry | null;
  public selectedAlias: UrlSiteAliasEntry | null;

  public isBigPractice = false;
  public selectedSiteId: string | null = "";
  public searchCriteria = "";
  public sites: Array<SiteEntry>;

  constructor(
    private _overlayService: OverlayService,
    private _notificationService: NotificationService,
    private _clipboard: Clipboard,
    private _jwtService: JWTService,
    private _devService: DevService,
    public commonService: CommonService,
    public portalViewerService: PortalViewerService,
    public featureFlagService: FeatureFlagsService
  ) {}

  public DEVICES = Enums.DEVICES;
  public permissionLevel = 0;
  public unlockingPatientId: string | null = null;

  @ViewChild("ngSelect") ngSelect: NgSelectComponent;
  @ViewChild("patientIframe", { static: false }) patientIFrame: ElementRef;
  @ViewChild("patientSearch", { static: false }) patientSearch: ElementRef;
  @ViewChild("brandsSearch", { static: false }) brandsSearch: ElementRef;

  public ngAfterViewInit(): void {
    this._focusSearch();
  }

  public ngOnInit(): void {
    this.isBigPractice = this.commonService.sites.length > 20;
    this.permissionLevel = this._jwtService.getJWT().permission_level;

    if (this.commonService.isMultiSite) {
      this.sites = cloneDeep(this.commonService.sites);
      if (!this.isBigPractice) this.sites.unshift(this.commonService.ALL_SITES);

      if (this.isBigPractice && this.commonService.selectedSiteId === this.commonService.ALL_SITES.id) {
        this.selectedSiteId = this.sites[0].id;
      } else {
        this.selectedSiteId = this.commonService.selectedSiteId;
      }
    }
  }

  // #region Common
  public brandSearchFn(word: string, item: BrandEntry) {
    return item.display_name.toLowerCase().includes(word.toLowerCase()) || item.uri.toLowerCase().includes(word.toLowerCase());
  }

  public onRadioChanged(newPatientSelected: boolean): void {
    this.selectedBrand = null;

    if (newPatientSelected) {
      // Reset the selected flag on the existing patient search result
      if (this.portalViewerService.searchResults) {
        for (const match of this.portalViewerService.searchResults) match.selected = false;
      }
    } else {
      // Need to call in a 0 timer to ensure the DOM changes have completed
      // (puts the call at the end of the main event queue)
      setTimeout(() => {
        fromEvent(this.patientSearch.nativeElement, "keyup")
          .pipe(
            debounceTime(500),
            map((e: Event) => (<HTMLInputElement>e.target).value),
            distinctUntilChanged(),
            tap((res) => this.performSearch(res))
          )
          .subscribe();
      }, 0);
    }
    this._focusSearch();
    this.portalModal.recalculateScroll();
  }

  private _focusSearch(): void {
    setTimeout(() => {
      if (this.patientSearch?.nativeElement) this.patientSearch.nativeElement.focus();
      if (this.brandsSearch?.nativeElement) this.brandsSearch.nativeElement.focus();
    }, 0);
  }

  public close(): void {
    if (this.patientIFrame) this.portalViewerService.close(this.patientIFrame?.nativeElement);
    this._overlayService.close();
  }

  public getSiteAliasDisplayName(brand: BrandEntry, site: SiteEntry): string {
    return `${brand.display_name} - ${site.display_name}`;
  }

  public getSiteAliasUri(brand: BrandEntry, site: SiteEntry): string {
    const { derived_uri } = brand;
    const alias = site.url_site_alias;
    return `${derived_uri}/practices/${alias.alias}`;
  }

  public getSiteAliasUrl(brand: BrandEntry, site?: SiteEntry): string {
    if (!site) {
      Bugsnag.notify(new Error("null site passed into getSiteAliasUrl"));
      throw new Error("null site passed into getSiteAliasUrl");
    }
    const { url } = brand;
    const alias = site.url_site_alias;

    return `${url}/practices/${alias.alias}`;
  }

  public siteAlias(brand: BrandEntry): string {
    const site = brand.applied_at_sites.items.find((sa) => sa.id === this.commonService.selectedSiteId);

    return this.getSiteAliasUrl(brand, site);
  }

  public openInNewTab(brand: BrandEntry, site?: SiteEntry) {
    if (!(brand instanceof BrandEntry)) brand = new BrandEntry(brand);
    window.open(this._getSiteUrl(brand, site), "_blank");
  }

  public copyToClipboard(brand: BrandEntry, site?: SiteEntry) {
    this._notificationService.open({
      type: NotificationTypes.INFO,
      title: "Copied to clipboard",
    });
    if (!(brand instanceof BrandEntry)) brand = new BrandEntry(brand);
    this._clipboard.copy(this._getSiteUrl(brand, site));
  }

  private _getSiteUrl(brand: BrandEntry, site?: SiteEntry): string {
    return site ? this.getSiteAliasUrl(brand, site) : brand.url;
  }
  // #endregion

  // #region Existing Patient
  public async performSearch(searchCriteria: string) {
    this.portalViewerService.searchResults.length = 0;
    if (searchCriteria.length < 3) {
      this.portalModal.recalculateScroll();
      return;
    }

    const siteId = this.selectedSiteId === this.commonService.ALL_SITES.id ? "" : this.selectedSiteId || "";
    await this.portalViewerService.search(searchCriteria, siteId);
    this.portalModal.recalculateScroll();
  }

  public async onMatchClicked(patient: PatientEntry) {
    if (patient.selected || (!patient.registered_portal_user && this.permissionLevel !== 5)) return;
    await this.portalViewerService.impersonateExistingPatient(patient, this.patientIFrame?.nativeElement);
  }

  public onInputKeyDown($event: KeyboardEvent) {
    if (!this._REGEX.test($event.key)) $event.preventDefault();
  }

  public onInputPaste($event: any) {
    const { clipboardData } = $event;
    const pastedText = clipboardData.getData("text");
    if (!this._REGEX.test(pastedText)) $event.preventDefault();
  }

  public async onSiteChanged(selectedSiteId: string) {
    this.selectedSiteId = selectedSiteId;
    await this.performSearch(this.searchCriteria);
  }

  private _hideEntry(entry: BrandEntry | SiteEntry, criteria = ""): boolean {
    return !entry.display_name.toLowerCase().includes(criteria.toLowerCase());
  }

  public filterBrands($event: KeyboardEvent) {
    const { value } = $event.target as HTMLInputElement;
    for (const brand of this.portalViewerService.appliedBrands) {
      for (const site of brand.applied_at_sites.items) site.url_site_alias.hidden = this._hideEntry(site, value);
      brand.hidden = this._hideEntry(brand, value);
    }
  }

  public get appliedPracticeBrands(): Array<BrandEntry> {
    return this.portalViewerService.appliedBrands.filter((brand) => brand.owning_site_id === null);
  }

  public get appliedSiteBrands(): Array<BrandEntry> {
    return this.portalViewerService.appliedBrands.filter((brand) => brand.owning_site_id !== null);
  }

  public get shownAppliedPracticeBrandCount(): number {
    return this.appliedPracticeBrands.filter((brand) => !brand.hidden).length;
  }

  public get shownAppliedSiteBrandCount(): number {
    return this.appliedSiteBrands.filter((brand) => !brand.hidden).length;
  }

  public async unlockPatientAccount(event: MouseEvent, patient: PatientEntry) {
    event.stopPropagation();

    this.unlockingPatientId = patient.id;

    await this.portalViewerService.unlockPatientAccount(patient).then(() => {
      this.portalViewerService.searchResults[this.portalViewerService.searchResults.findIndex((p) => p.id === patient.id)].is_account_locked = false;

      this.unlockingPatientId = null;
    });
  }

  public async sendTaskReminder(patient: PatientEntry) {
    if (!(await this.portalViewerService.sendTaskReminderNotification(patient))) {
      alert("Couldn't send task reminder");
    }
  }

  public get shouldShowSendTaskReminder(): boolean {
    return this._devService.isUserDev && !environment.IS_PROD;
  }
  // #endregion

  // #region New Patient
  private _impersonateNewPatient(uri: string): void {
    if (this.ngSelect) this.ngSelect.blur();
    this.portalViewerService.impersonateNewPatient(uri, this.patientIFrame?.nativeElement);
  }

  public onSelectedBrandChanged(brand: BrandEntry): void {
    this.selectedBrand = this.portalViewerService.appliedBrands.find((item) => item.derived_uri === brand.derived_uri) || null;
    this.selectedAlias = null;
    this._impersonateNewPatient(brand.derived_uri);
  }

  public onSelectedBrandAliasChanged(alias: UrlSiteAliasEntry): void {
    this.selectedAlias = alias;
    this.selectedBrand = null;
    this._impersonateNewPatient(alias.alias_uri);
  }
  // #endregion
}
