import forOwn from "lodash/forOwn";
import { PracticeBase } from "@backend/graph/practices/practice-base";
import { BaseListBase } from "@backend/graph/_resolvers/base/base-list-base";
import { AllowedPaymentProviderListEntry } from "./allowed-payment-provider";
import { AppointmentReasonListEntry } from "./appointment-reason";
import { AppointmentTypeEntry } from "./appointment-type";
import { BrandListEntry, CustomDomainEntry, DefaultDomainEntry } from "./brands";
import { FeaturesEntry } from "./features";
import { StatisticsEntry } from "./statistics";
import { PracticeAppointmentTypeCategoriesEntry } from "./practice-appointment-type-categories";
import { PracticeConsultationTypeListEntry } from "./practice-consultation-types";
import { PractitionerEntry } from "./practitioner";
import { SiteEntry, SiteListEntry } from "./site";
import { E_Stats_Names } from "../shared/services/statistics-graph-queries";
import { PracticeSettingsEntry } from "./practice-settings";

export class PracticeEntry extends PracticeBase {
  constructor(baseItem?: PracticeBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }

  public refresh_status: string;
  public refresh_syncing: boolean;
  public last_refresh_time: Date;
  public last_complete_sync_time: Date;
  public name: string;
  public nhs_payment_plan_id: string;
  public nhs: boolean;
  public nhs_country: string;
  public iso_country_code: string;
  public is_multisite: boolean;
  public currency_code: string;
  public time_zone: string;
  public logo_url: string;
  public requires_migration: boolean;

  public appointment_type_categories: BaseListBase<PracticeAppointmentTypeCategoriesEntry>;
  public allowed_payment_providers: AllowedPaymentProviderListEntry;
  public all_applied_brands: BrandListEntry;
  public appointment_reasons: AppointmentReasonListEntry;
  public appointment_type: AppointmentTypeEntry;
  public available_custom_domains: BaseListBase<CustomDomainEntry>;
  public available_default_domains: BaseListBase<DefaultDomainEntry>;
  public features: FeaturesEntry;
  public [E_Stats_Names.ONLINE_SIGNING]: Array<StatisticsEntry>;
  public [E_Stats_Names.APPOINTMENTS]: Array<StatisticsEntry>;
  public owned_brands: BrandListEntry;
  public site: SiteEntry;
  public sites: SiteListEntry;
  public practice_consultation_types: PracticeConsultationTypeListEntry;
  public practitioners: BaseListBase<PractitionerEntry>;
  public settings: PracticeSettingsEntry;
}
