import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { nanoid } from "nanoid";
import { catchError, map } from "rxjs/operators";
import { SaverService, SaverStatuses } from "./saver.service";
import { CookieService } from "./cookie.service";
import { Constants } from "src/constants";
import Bugsnag from "@bugsnag/js";
import { TRACE_ID_HEADER } from "@shared/constants";
import { generateNanoId } from "@shared/utils";
import { JWTService } from "./jwt.service";
import { SessionService } from "./session.service";
import { environment } from "src/environments/environment";

const { HOST_ZONE } = environment;

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private _request: HttpRequest<any>;
  private _graphCalls: Array<string> = [];

  constructor(
    private _saverService: SaverService,
    private _cookieService: CookieService,
    private _jwtService: JWTService,
    private _sessionService: SessionService
  ) {}

  private _isGraphCall() {
    return this._request.url.includes(`graph.${HOST_ZONE}`);
  }

  private _isRestCall() {
    return this._request.url.includes(`rest.${HOST_ZONE}`);
  }

  private _isGraphMutation() {
    if (this._isRestCall() || !this._request.body?.query) return false;
    const isMutation = this._request.body.query.trim().startsWith("mutation");
    const isRefreshCache = this._request.body.query.includes("queueRefreshCache");
    const isOAuthQuery = this._request.body.query.includes("OAuth");
    return isMutation && this._isGraphCall() && !isRefreshCache && !isOAuthQuery;
  }

  private _isApiCall(): boolean {
    return this._request.url.includes(`apis.${HOST_ZONE}`);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._request = request;

    // Update the saver component
    if (this._isGraphMutation()) {
      this._saverService.saverStatus = SaverStatuses.SAVING;
      this._graphCalls.push(nanoid(12));
    }

    if (this._request.url.includes(".dentr.")) {
      const message = "Request to dentr domain";

      Bugsnag.leaveBreadcrumb(message, { url: this._request.url });
      Bugsnag.notify(new Error(message));
    }

    // Add relevant auth headers only to Graph or api calls
    if ((!this._isRestCall() && this._isGraphCall()) || this._isApiCall()) {
      const jwt = this._jwtService.getJWTString();

      if (!jwt) {
        Bugsnag.notify(new Error("No JWT found - cant make request"));
        throw new Error("No JWT found - cant make request");
      }
      this._request = request.clone({
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: `${jwt}`,
        }),
      });
    }

    this._request = this._request.clone({
      setHeaders: {
        [TRACE_ID_HEADER]: `manage-${generateNanoId()}`,
      },
    });

    return next
      .handle(this._request)
      .pipe(
        catchError(async (err) => {
          console.error("http interceptor: error making request to", err);
          if (err.status === 403 || err.status === 401) {
            await this._sessionService.clear();
            this._cookieService.deleteCookie(Constants.SITE_SELECTION_COOKIE);
            window.location.reload();
          }
          // Update the saver component to represent an error
          if (this._isGraphMutation()) this._saverService.saverStatus = SaverStatuses.ERROR;
          return err;
        })
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            if (!this._isRestCall()) {
              this._graphCalls.shift();
              if (evt.body.errors) {
                this._saverService.saverStatus = SaverStatuses.ERROR;
              } else if (this._isGraphMutation() && !this._graphCalls.length && this._saverService.saverStatus !== SaverStatuses.ERROR) {
                // Only update the saver component if there are no more graph calls and none of the requests errored
                this._saverService.saverStatus = SaverStatuses.SAVED;
              }
            }
          }
          return evt;
        })
      );
  }
}
