import { SHARED } from "src/app/shared/shared";
import { AfterViewInit, Component, ContentChild, ElementRef, Input, ViewChild } from "@angular/core";
import { SubSink } from "subsink";
import { OverlayService } from "../../services/overlay.service";
import { SCALE_ANIMATION } from "../../utils/animations";

@Component({
  selector: "dentr-modal",
  templateUrl: "modal.component.html",
  standalone: true,
  imports: [SHARED],
  animations: [SCALE_ANIMATION(0.99)],
})
export class ModalComponent implements AfterViewInit {
  @Input() public gridLayout = false;
  @Input() public autoWidth = false;
  @Input() id = "modal";
  @ContentChild("modalScroll") modalScrollEl: ElementRef;
  @ViewChild("modal") modal: ElementRef;

  private _subs = new SubSink();
  private _resizeTimer: any;

  constructor(private _overlayService: OverlayService) {}

  public ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private get _modalScrollElement(): HTMLElement {
    return this.modalScrollEl?.nativeElement;
  }

  private _modalSetup(): void {
    setTimeout(() => {
      const el = this._modalScrollElement;
      // Quickly set the height to auto to get the real height of the content before we calculate the scrollable area
      el.style.height = "auto";
      const bottomOfContent = el.getBoundingClientRect().bottom;
      const remaining_area = window.innerHeight - el.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const isContentTallerThanWindow = bottomOfContent > windowHeight;

      if (isContentTallerThanWindow) {
        el.style.height = `${remaining_area - 50}px`;
        el.classList.add(`overflow-y-auto`);
      } else {
        el.style.height = "auto";
        el.classList.remove(`overflow-y-auto`);
      }
    });
  }

  public recalculateScroll(): void {
    this._modalSetup();
  }

  public ngAfterViewInit() {
    if (this._modalScrollElement) {
      this._resizeTimer = null;
      window.onresize = () => {
        clearTimeout(this._resizeTimer);
        this._resizeTimer = setTimeout(() => {
          this._modalSetup();
        }, 500);
      };
      this._modalSetup();
    }
  }

  public close($event: Event): void {
    const element = this.modal.nativeElement;

    // Only close the modal if clicking outside the content
    if ($event.target === element && element.contains($event.target)) {
      this._overlayService.close();
    }
  }
}
