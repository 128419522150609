/* eslint-disable */

export function initialise_intercom(current_user, app_id: string) {
  (window as any).intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id,
    name: current_user.name, // Full name
    email: current_user.email, // Email address
    created_at: current_user.created_at, // Signup date as a Unix timestamp
    user_hash: current_user.user_hash,
    user_id: current_user.user_id,
  };

  (function () {
    var w = window as any;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i: any = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${app_id}`;
        var x = d.getElementsByTagName("script")[0];
        x.parentNode?.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
}
