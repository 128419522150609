import { SiteSettingsBase } from "../../../../../backend/src/graph/site_settings/site-settings-base";
import forOwn from "lodash/forOwn";
import merge from "lodash/merge";

import { Subject } from "rxjs";

export class SiteSettingsEntry extends SiteSettingsBase {
  public static onMerge = new Subject<SiteSettingsEntry>();

  constructor(baseItem?: SiteSettingsBase) {
    super();
    if (!baseItem) return;

    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }

  public merge(item: SiteSettingsEntry) {
    merge(this, item);
    SiteSettingsEntry.onMerge.next(this);
  }
}
