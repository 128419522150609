import { getStageSpecificValue } from "@shared/parameter-store-utils";

/**
 * The placeholder values are replaced by:
 * '_getReplaceValues' when deploying through SST
 * 'dev/devenv.ts' when running locally
 */
export const environment = {
  BACKEND_DOMAIN: "#{BACKEND_DOMAIN}#",
  get GRAPH_URL() {
    return `https://${this.STAGE}-graph.${this.BACKEND_DOMAIN}/`;
  },
  HOST_ZONE: "#{HOST_ZONE}#",
  INTERCOM_APP_ID: "#{INTERCOM_APP_ID}#",
  get IS_PROD() {
    return ["true"].includes("#{IS_PROD}#"); // To prevent "This comparison appears to be unintentional because the types '"#{IS_PROD}#"' and '"true"' have no overlap" error
  },
  get PMS_URL() {
    const urls = JSON.parse(this.PMS_URL_STRING);

    return getStageSpecificValue(this.STAGE, urls);
  },
  get PMS_URL_STRING() {
    return "#{PMS_URL}#";
  },
  PUSHER_CLUSTER: "#{PUSHER_CLUSTER}#",
  PUSHER_EVENT: "#{PUSHER_EVENT}#",
  PUSHER_KEY: "#{PUSHER_KEY}#",
  get REGION() {
    return window.REGION || "eu-west-1";
  },
  get REST_URL() {
    return `https://${this.STAGE}-rest.${this.BACKEND_DOMAIN}`;
  },
  get REST_LEGACY_URL() {
    return `https://${this.STAGE}-apis.${this.BACKEND_DOMAIN}`;
  },
  get STAGE() {
    return window.STAGE || "#{STAGE}#";
  },
  production: true,
  VERSION: "#{VERSION}#",
};
