import flatten from "lodash/flatten";
import isArray from "lodash/isArray";
import Pusher from "pusher-js";
import { merge, Observable, Subject } from "rxjs";
import { bufferCount, distinct, filter, groupBy, map, mergeMap, tap } from "rxjs/operators";
import { AppointmentSettingsEntry } from "src/app/data_model/appointment-settings";
import { AppointmentTypeEntry } from "src/app/data_model/appointment-type";
import { BrandCustomDomainEntry, BrandDefaultDomainEntry, BrandEntry } from "src/app/data_model/brands";
import { PractitionerEntry } from "src/app/data_model/practitioner";
import { SiteEntry } from "src/app/data_model/site";
import { SiteAllowedAppointmentTypeEntry } from "src/app/data_model/site-allowed-appointment-type";
import { SiteAppointmentTypeEntry } from "src/app/data_model/site-appointment-type";
import { SiteAppointmentTypePractitionerDurationEntry } from "src/app/data_model/site-appointment-type-practitioner-duration";
import { SitePaymentPlanEntry } from "src/app/data_model/site-payment-plan";
import { SitePaymentPlanAllowanceEntry } from "src/app/data_model/site-payment-plan-allowance";
import { SitePaymentPlanDiscountEntry } from "src/app/data_model/site-payment-plan-discount";
import { SiteSettingsEntry } from "src/app/data_model/site-settings";
import { UserEntry } from "src/app/data_model/user";

import { Injectable } from "@angular/core";

import { E_MessageKind } from "../../../../../../backend/src/graph/_services/e-message-kind";
import { SiteAppointmentTypeSessionBase } from "../../../../../../backend/src/graph/site_appointment_type_sessions/site-appointment-type-session-base";
import { EnvService } from "./env.service";
import { FeaturesEntry } from "src/app/data_model/features";
import { SitePaymentPlanCategoryBase } from "@backend/graph/site_payment_plan_categories/site-payment-plan-category-base";
import { I_DownForMaintenanceMessage } from "@backend/common/interfaces/down-for-maintenance-message.interface";
import { UrlSiteAliasEntry } from "src/app/data_model/url-site-alias";
import { NotificationService } from "./notification.service";
import { PracticeSettingsEntry } from "src/app/data_model/practice-settings";
import { LoggedInUserSettingsEntry } from "src/app/data_model/logged-in-user-settings";
import { DeviceEntry } from "src/app/data_model/devices";

export enum E_Event {
  Created,
  Deleted,
  Updated,
}

class BaseSink<T> {
  public item: T;
  public event: E_Event;
  public notificationService?: NotificationService;

  constructor(item: T, event: E_Event, notificationService?: NotificationService) {
    this.item = item;
    this.event = event;
    this.notificationService = notificationService;
  }
}

export enum E_RefreshEvent {
  Started,
  Progressed,
  Finished,
}

export class RefreshSink {
  public message: string;
  public event: E_RefreshEvent;

  constructor(message: string, event: E_RefreshEvent) {
    this.message = message;
    this.event = event;
  }
}

export class AppointmentSettingsSink extends BaseSink<AppointmentSettingsEntry> {}
export class AppointmentTypeSink extends BaseSink<AppointmentTypeEntry> {}
export class BrandSink extends BaseSink<BrandEntry> {}
export class BrandCustomDomainSink extends BaseSink<BrandCustomDomainEntry> {}
export class BrandDefaultDomainSink extends BaseSink<BrandDefaultDomainEntry> {}
export class DeviceSink extends BaseSink<DeviceEntry> {}
export class FeaturesSink extends BaseSink<FeaturesEntry> {}
export class LoggedInUserSettingsSink extends BaseSink<LoggedInUserSettingsEntry> {}
export class PracticeSettingsSink extends BaseSink<PracticeSettingsEntry> {}
export class PractitionerSink extends BaseSink<PractitionerEntry> {}
export class SiteSink extends BaseSink<SiteEntry> {}
export class SiteAllowedAppointmentTypeSink extends BaseSink<SiteAllowedAppointmentTypeEntry> {}
export class SiteAppointmentTypeSink extends BaseSink<SiteAppointmentTypeEntry> {}
export class SiteAppointmentTypePractitionerSink extends BaseSink<SiteAppointmentTypePractitionerDurationEntry> {}
export class SiteAppointmentTypeSessionSink extends BaseSink<SiteAppointmentTypeSessionBase> {}
export class SitePaymentPlanCategorySink extends BaseSink<SitePaymentPlanCategoryBase> {}
export class SitePaymentPlanSink extends BaseSink<SitePaymentPlanEntry> {}
export class SitePaymentPlanAllowanceSink extends BaseSink<SitePaymentPlanAllowanceEntry> {}
export class SitePaymentPlanDiscountSink extends BaseSink<SitePaymentPlanDiscountEntry> {}
export class SiteSettingsSink extends BaseSink<SiteSettingsEntry> {}
export class UrlSiteAliasSink extends BaseSink<UrlSiteAliasEntry> {}
export class UserSink extends BaseSink<UserEntry> {}

@Injectable({
  providedIn: "root",
})
export class PushUpdatesService {
  private _pusher: Pusher;
  private _isConnected = false;

  public AppointmentSettingsSubject = new Subject<AppointmentSettingsSink>();
  public AppointmentTypeSubject = new Subject<AppointmentTypeSink>();
  public BrandSubject = new Subject<BrandSink>();
  public BrandCustomDomainSubject = new Subject<BrandCustomDomainSink>();
  public BrandDefaultDomainSubject = new Subject<BrandDefaultDomainSink>();
  public DeviceSubject = new Subject<DeviceSink>();
  public DownForMaintenanceSubject = new Subject<I_DownForMaintenanceMessage>();
  public FeaturesSubject = new Subject<FeaturesSink>();
  public LoggedInUserSettingsSubject = new Subject<LoggedInUserSettingsSink>();
  public PracticeSettingsSubject = new Subject<PracticeSettingsSink>();
  public PractitionerSubject = new Subject<PractitionerSink>();
  public RefreshSubject = new Subject<RefreshSink>();
  public SiteAllowedAppointmentTypeSubject = new Subject<SiteAllowedAppointmentTypeSink>();
  public SiteAppointmentTypePractitionerSubject = new Subject<SiteAppointmentTypePractitionerSink>();
  public SiteAppointmentTypeSessionSubject = new Subject<SiteAppointmentTypeSessionSink>();
  public SiteAppointmentTypeSubject = new Subject<SiteAppointmentTypeSink>();
  public SitePaymentPlanAllowanceSubject = new Subject<SitePaymentPlanAllowanceSink>();
  public SitePaymentPlanDiscountSubject = new Subject<SitePaymentPlanDiscountSink>();
  public SitePaymentPlanCategorySubject = new Subject<SitePaymentPlanCategorySink>();
  public SitePaymentPlanSubject = new Subject<SitePaymentPlanSink>();
  public SiteSettingsSubject = new Subject<SiteSettingsSink>();
  public SiteSubject = new Subject<SiteSink>();
  public static UrlSiteAliasSubject = new Subject<UrlSiteAliasSink>();
  public UserSubject = new Subject<UserSink>();

  constructor(private _envService: EnvService, private _notificationService: NotificationService) {}

  public connect(practiceId: string, Authorization: string) {
    if (this._isConnected) {
      console.log("Already connected to Pusher");
      return;
    }

    try {
      this._pusher = new Pusher(this._envService.env.PUSHER_KEY, {
        cluster: this._envService.env.PUSHER_CLUSTER,
        forceTLS: true,
        authEndpoint: `${this._envService.env.REST_URL}/api/admin/pusher/auth`,
        auth: {
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
        },
      });

      const practiceSpecificChannelName = `private-${this._envService.env.STAGE}-${practiceId}-manage`;
      const genericChannelName = `private-${this._envService.env.STAGE}-manage`;

      // eslint-disable-next-line complexity
      merge(this._createObservable(practiceSpecificChannelName), this._createObservable(genericChannelName)).subscribe((data) => {
        let items: Array<any>;
        if (isArray(data.message_content)) {
          if (data.message_content.length > 0 && isArray(data.message_content[0])) {
            items = flatten(data.message_content);
          } else {
            items = data.message_content;
          }
        } else {
          items = [data.message_content];
        }

        for (const item of items) {
          // Message received
          switch (data.message_kind) {
            // AppointmentSettings
            case E_MessageKind.AppointmentSettings_Updated:
              this.AppointmentSettingsSubject.next(new AppointmentSettingsSink(item, E_Event.Updated));
              break;

            // AppointmentType
            case E_MessageKind.AppointmentType_Created:
              this.AppointmentTypeSubject.next(new AppointmentTypeSink(item, E_Event.Created));
              break;
            case E_MessageKind.AppointmentType_Deleted:
              this.AppointmentTypeSubject.next(new AppointmentTypeSink(item, E_Event.Deleted));
              break;
            case E_MessageKind.AppointmentType_Updated:
              this.AppointmentTypeSubject.next(new AppointmentTypeSink(item, E_Event.Updated));
              break;

            // Brand
            case E_MessageKind.Brand_Created:
              this.BrandSubject.next(new BrandSink(data.message_content, E_Event.Created));
              break;
            case E_MessageKind.Brand_Deleted:
              this.BrandSubject.next(new BrandSink(data.message_content, E_Event.Deleted));
              break;
            case E_MessageKind.Brand_Updated:
              this.BrandSubject.next(new BrandSink(data.message_content, E_Event.Updated));
              break;

            // BrandCustomDomain
            case E_MessageKind.BrandCustomDomain_Created:
              this.BrandCustomDomainSubject.next(new BrandCustomDomainSink(data.message_content, E_Event.Created));
              break;
            case E_MessageKind.BrandCustomDomain_Deleted:
              this.BrandCustomDomainSubject.next(new BrandCustomDomainSink(data.message_content, E_Event.Deleted));
              break;

            // BrandDefaultDomain
            case E_MessageKind.BrandDefaultDomain_Created:
              this.BrandDefaultDomainSubject.next(new BrandDefaultDomainSink(data.message_content, E_Event.Created));
              break;
            case E_MessageKind.BrandDefaultDomain_Deleted:
              this.BrandDefaultDomainSubject.next(new BrandDefaultDomainSink(data.message_content, E_Event.Deleted));
              break;

            // UrlSiteAlias
            case E_MessageKind.UrlSiteAlias_Updated:
              PushUpdatesService.UrlSiteAliasSubject.next(new UrlSiteAliasSink(data.message_content, E_Event.Updated, this._notificationService));
              break;

            case E_MessageKind.Features_Updated:
              this.FeaturesSubject.next(new FeaturesSink(data.message_content, E_Event.Updated));
              break;

            // PracticeSettings
            case E_MessageKind.PracticeSettings_Updated:
              this.PracticeSettingsSubject.next(new PracticeSettingsSink(data.message_content, E_Event.Updated));
              break;

            // Practitioner
            case E_MessageKind.Practitioner_Created:
              this.PractitionerSubject.next(new PractitionerSink(data.message_content, E_Event.Created));
              break;
            case E_MessageKind.Practitioner_Deleted:
              this.PractitionerSubject.next(new PractitionerSink(data.message_content, E_Event.Deleted));
              break;
            case E_MessageKind.Practitioner_Updated:
              this.PractitionerSubject.next(new PractitionerSink(data.message_content, E_Event.Updated));
              break;

            // Refresh
            case E_MessageKind.Refresh_Started:
              this.RefreshSubject.next(new RefreshSink(item, E_RefreshEvent.Started));
              break;
            case E_MessageKind.Refresh_Progressed:
              this.RefreshSubject.next(new RefreshSink(item, E_RefreshEvent.Progressed));
              break;
            case E_MessageKind.Refresh_Finished:
              this.RefreshSubject.next(new RefreshSink(item, E_RefreshEvent.Finished));
              break;

            // Site
            case E_MessageKind.Site_Updated:
              this.SiteSubject.next(new SiteSink(item, E_Event.Updated));
              break;

            // SiteAllowedAppointmentType
            case E_MessageKind.SiteAllowedAppointmentType_Created:
              this.SiteAllowedAppointmentTypeSubject.next(new SiteAllowedAppointmentTypeSink(item, E_Event.Created));
              break;
            case E_MessageKind.SiteAllowedAppointmentType_Deleted:
              this.SiteAllowedAppointmentTypeSubject.next(new SiteAllowedAppointmentTypeSink(item, E_Event.Deleted));
              break;

            // SiteAppointmentType
            case E_MessageKind.SiteAppointmentType_Created:
              this.SiteAppointmentTypeSubject.next(new SiteAppointmentTypeSink(item, E_Event.Created));
              break;
            case E_MessageKind.SiteAppointmentType_Deleted:
              this.SiteAppointmentTypeSubject.next(new SiteAppointmentTypeSink(item, E_Event.Deleted));
              break;
            case E_MessageKind.SiteAppointmentType_Updated:
              this.SiteAppointmentTypeSubject.next(new SiteAppointmentTypeSink(item, E_Event.Updated));
              break;

            // SiteAppointmentTypePractitioner
            case E_MessageKind.SiteAppointmentTypePractitioner_Created:
              this.SiteAppointmentTypePractitionerSubject.next(new SiteAppointmentTypePractitionerSink(item, E_Event.Created));
              break;
            case E_MessageKind.SiteAppointmentTypePractitioner_Deleted:
              this.SiteAppointmentTypePractitionerSubject.next(new SiteAppointmentTypePractitionerSink(item, E_Event.Deleted));
              break;
            case E_MessageKind.SiteAppointmentTypePractitioner_Updated:
              this.SiteAppointmentTypePractitionerSubject.next(new SiteAppointmentTypePractitionerSink(item, E_Event.Updated));
              break;

            // SiteAppointmentTypeSession
            case E_MessageKind.SiteAppointmentTypeSession_Created:
              this.SiteAppointmentTypeSessionSubject.next(new SiteAppointmentTypeSessionSink(item, E_Event.Created));
              break;

            case E_MessageKind.SiteAppointmentTypeSession_Deleted:
              this.SiteAppointmentTypeSessionSubject.next(new SiteAppointmentTypeSessionSink(item, E_Event.Deleted));
              break;

            // SitePaymentPlanCategory
            case E_MessageKind.SitePaymentPlanCategory_Updated:
              this.SitePaymentPlanCategorySubject.next(new SitePaymentPlanCategorySink(item, E_Event.Updated));
              break;

            // SitePaymentPlan
            case E_MessageKind.SitePaymentPlan_Created:
              this.SitePaymentPlanSubject.next(new SitePaymentPlanSink(item, E_Event.Created));
              break;

            case E_MessageKind.SitePaymentPlan_Deleted:
              this.SitePaymentPlanSubject.next(new SitePaymentPlanSink(item, E_Event.Deleted));
              break;

            case E_MessageKind.SitePaymentPlan_Updated:
              this.SitePaymentPlanSubject.next(new SitePaymentPlanSink(item, E_Event.Updated));
              break;

            // SitePaymentPlanAllowance
            case E_MessageKind.SitePaymentPlanAllowance_Created:
              this.SitePaymentPlanAllowanceSubject.next(new SitePaymentPlanAllowanceSink(item, E_Event.Created));
              break;

            case E_MessageKind.SitePaymentPlanAllowance_Deleted:
              this.SitePaymentPlanAllowanceSubject.next(new SitePaymentPlanAllowanceSink(item, E_Event.Deleted));
              break;

            case E_MessageKind.SitePaymentPlanAllowance_Updated:
              this.SitePaymentPlanAllowanceSubject.next(new SitePaymentPlanAllowanceSink(item, E_Event.Updated));
              break;

            // SitePaymentPlanDiscount
            case E_MessageKind.SitePaymentPlanDiscount_Created:
              this.SitePaymentPlanDiscountSubject.next(new SitePaymentPlanDiscountSink(item, E_Event.Created));
              break;

            case E_MessageKind.SitePaymentPlanDiscount_Deleted:
              this.SitePaymentPlanDiscountSubject.next(new SitePaymentPlanDiscountSink(item, E_Event.Deleted));
              break;

            case E_MessageKind.SitePaymentPlanDiscount_Updated:
              this.SitePaymentPlanDiscountSubject.next(new SitePaymentPlanDiscountSink(item, E_Event.Updated));
              break;

            // SiteSettings
            case E_MessageKind.SiteSettings_Updated:
              this.SiteSettingsSubject.next(new SiteSettingsSink(item, E_Event.Updated));
              break;

            // User
            case E_MessageKind.User_Updated:
              this.UserSubject.next(new UserSink(item, E_Event.Updated));
              break;

            // Devices
            case E_MessageKind.Device_Created:
              this.DeviceSubject.next(new DeviceSink(item, E_Event.Created));
              break;

            case E_MessageKind.Device_Deleted:
              this.DeviceSubject.next(new DeviceSink(item, E_Event.Deleted));
              break;

            case E_MessageKind.Device_Updated:
              this.DeviceSubject.next(new DeviceSink(item, E_Event.Updated));
              break;

            // Down for maintenance
            case E_MessageKind.DownForMaintenance:
              this.DownForMaintenanceSubject.next(item);
              break;

            case E_MessageKind.LoggedInUserSettings_Updated:
              this.LoggedInUserSettingsSubject.next(new LoggedInUserSettingsSink(item, E_Event.Updated));
              break;
            case E_MessageKind.LoggedInUserSettings_Created:
              this.LoggedInUserSettingsSubject.next(new LoggedInUserSettingsSink(item, E_Event.Created));
              break;

            case E_MessageKind.Features_Updated:
              this.FeaturesSubject.next(new FeaturesSink(item, E_Event.Updated));
              break;

            default:
              console.log(data, "UNKNOWN DATA");
          }
        }
      });

      console.log("Connected to Pusher");
      this._isConnected = true;
    } catch (err) {
      console.error(err, "Failed to connect to Pusher");
    }
  }

  /**
   * Creates an Observable based on the Pusher subscription which will combine partial messages before emitting them
   *
   * @param channelName Name of the channel
   */
  private _createObservable(channelName: string): Observable<any> {
    const partsByUid = {};
    const subject = new Subject<any>();
    const channel = this._pusher.subscribe(channelName);

    channel.bind(this._envService.env.PUSHER_EVENT, (data) => {
      subject.next(data);
    });

    return merge(
      subject.pipe(filter((message) => message._meta?.mode !== "split")),
      subject.pipe(
        filter((message) => message._meta?.mode === "split"),
        // Store the number of parts for each unique split message
        tap((item) => {
          partsByUid[item._meta.uid] = item._meta.parts;
        }),
        // Group the parts by the unique ID so they can be buffered and then combined
        groupBy((item) => item._meta.uid),
        // Buffer the parts until we have received all parts
        mergeMap((group) =>
          group.pipe(
            distinct((part) => part._meta.part),
            bufferCount(partsByUid[group.key])
          )
        ),
        // Combine the parts to form the original JSON string and then parse to an object
        map((parts) => {
          delete partsByUid[parts[0]._meta.uid];

          return JSON.parse(
            parts
              .sort((a, b) => a._meta.part - b._meta.part)
              .map((part) => part.data)
              .join("")
          );
        })
      )
    );
  }
}
