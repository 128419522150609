import { Injectable } from "@angular/core";
import dayjs from "dayjs";
import { BehaviorSubject, timer } from "rxjs";
import { SubSink } from "subsink";

@Injectable({
  providedIn: "root",
})
export class ClientDevicesService {
  public onTimeChanged = new BehaviorSubject(dayjs().format("H:mm"));
  public onBatteryLevelChanged = new BehaviorSubject<number>(50);
  private _subs = new SubSink();

  constructor() {
    this._startTimer();
  }

  private _startTimer() {
    setInterval(() => {
      const current_time = dayjs().format("H:mm");
      // Only update the value if the minute or hour is different
      if (this.onTimeChanged.value !== current_time) this.onTimeChanged.next(current_time);
    }, 1000);

    if (window.navigator.getBattery) {
      this._subs.sink = timer(0, 60000).subscribe(() => {
        window.navigator
          .getBattery()
          .then((battery) => {
            if (battery?.level) this.onBatteryLevelChanged.next(battery.level * 100);
          })
          .catch(() => {});
      });
    }
  }
}
