import { SHARED } from "src/app/shared/shared";
import { Component } from "@angular/core";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { heroExclamationCircleSolid, heroCheckCircleSolid, heroArrowPathSolid } from "@ng-icons/heroicons/solid";
import { CommonService } from "src/app/shared/services/common.service";
import { SaverStatuses, SaverService } from "src/app/shared/services/saver.service";
@Component({
  selector: "dentr-saver",
  templateUrl: "./saver.component.html",
  standalone: true,
  imports: [SHARED, NgIconComponent],
  providers: [provideIcons({ heroExclamationCircleSolid, heroCheckCircleSolid, heroArrowPathSolid })],
})
export class SaverComponent {
  public SaverStatuses: typeof SaverStatuses;

  constructor(public saverService: SaverService, public commonService: CommonService) {
    this.SaverStatuses = SaverStatuses;
  }
}
