import { BrandBase } from "@backend/graph/brands/brands-base";
import { BaseListBase } from "@backend/graph/_resolvers/base/base-list-base";
import { BrandCustomDomainBase } from "@backend/graph/brand_custom_domains/brand-custom-domain-base";
import { CustomDomainBase } from "@backend/graph/custom_domains/custom-domain-base";
import { BrandDefaultDomainBase } from "@backend/graph/brand_default_domains/brand-default-domain-base";
import { DefaultDomainBase } from "@backend/graph/default_domains/default-domain-base";
import forOwn from "lodash/forOwn";
import { UrlSiteAliasEntry } from "./url-site-alias";
import { SiteEntry } from "./site";
import { LocationEx } from "../shared/utils/location";
import { SubSink } from "subsink";
import { generateNanoId } from "@shared/utils";

export class CustomDomainEntry extends CustomDomainBase {
  public using_brand: BrandCustomDomainEntry | null;

  constructor(baseItem?: CustomDomainBase | CustomDomainEntry) {
    super(null);
    if (!baseItem) return;

    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}

export class DefaultDomainEntry extends DefaultDomainBase {
  constructor(baseItem?: DefaultDomainBase | DefaultDomainEntry) {
    super(null);
    if (!baseItem) return;

    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}

export class BrandCustomDomainEntry extends BrandCustomDomainBase {
  public custom_domain: CustomDomainEntry;

  constructor(baseItem?: BrandCustomDomainBase | BrandCustomDomainEntry) {
    super(null);
    if (!baseItem) return;

    forOwn(baseItem, (value, key) => {
      switch (key) {
        case "custom_domain":
          if (value) this.custom_domain = new CustomDomainEntry(value as any);
          break;

        default:
          this[key] = value;
      }
    });
  }
}

export class BrandDefaultDomainEntry extends BrandDefaultDomainBase {
  public default_domain: DefaultDomainEntry;

  constructor(baseItem?: BrandDefaultDomainBase | BrandDefaultDomainEntry) {
    super(null);
    if (!baseItem) return;

    forOwn(baseItem, (value, key) => {
      switch (key) {
        case "default_domain":
          if (value) this.default_domain = new DefaultDomainEntry(value as any);
          break;

        default:
          this[key] = value;
      }
    });
  }
}

export class BrandEntry extends BrandBase {
  public linked_sites = new Array<string>();
  public applied_at_sites = new BaseListBase<SiteEntry>();
  public image_base64: string | null;
  public favicon_base64?: string | null = null;
  public is_selected = false;
  public subdomain_available = false;
  public subdomain_confirmed = false;
  public site_locking_enabled = false;
  public site_aliases = new BaseListBase<UrlSiteAliasEntry>();
  public original_site_aliases = new Array<UrlSiteAliasEntry>();
  public hidden = false;
  public used_custom_domain: BrandCustomDomainEntry | null;
  public used_default_domain: BrandDefaultDomainEntry | null;

  private _usedDomainKind: "default_domain" | "custom_domain" | "undefined" = "undefined";

  public get used_domain_kind(): "default_domain" | "custom_domain" {
    this._setIntitialDomainKind();
    return this._usedDomainKind as any;
  }

  public set used_domain_kind(value: "default_domain" | "custom_domain") {
    this._usedDomainKind = value;
  }

  public get shown_aliases_count(): number {
    return this.applied_at_sites?.items?.filter((item) => !item.url_site_alias?.hidden).length || 0;
  }

  private _subs = new SubSink();

  public unsubscribe(): void {
    this._subs.unsubscribe();
  }

  private _setIntitialDomainKind(): void {
    if (this._usedDomainKind !== "undefined") return;
    this._usedDomainKind = this.used_custom_domain != null ? "custom_domain" : "default_domain";
  }

  constructor(baseItem?: BrandBase | BrandEntry) {
    super(null);
    if (!baseItem) return;

    // Set the favicon_base64 to undefined if we have a favicon_preview_url so that we can detect a change back to the default favicon
    if (baseItem.favicon_preview_url) this.favicon_base64 = undefined;

    // eslint-disable-next-line complexity
    forOwn(baseItem, (value, key) => {
      switch (key) {
        case "owner":
        case "default_url":
        case "url":
        case "derived_uri":
        case "used_domain_kind":
        case "shown_aliases_count":
        case "linked_sites_total":
        case "url":
        case "_derivedSubdomain":
          break;

        case "site_aliases":
          this.site_aliases.items = new Array<UrlSiteAliasEntry>();
          if ((baseItem as any).site_aliases?.items) {
            for (const alias of (baseItem as any).site_aliases.items) {
              this.site_aliases.items.push(new UrlSiteAliasEntry(alias, this));
            }
          }
          break;

        case "used_custom_domain":
          if (value) this.used_custom_domain = new BrandCustomDomainEntry(value as any);
          break;

        case "used_default_domain":
          if (value) this.used_default_domain = new BrandDefaultDomainEntry(value as any);
          break;

        default:
          this[key] = value;
      }
    });

    // Trigger the used_domain_kind getter so we set the inital value of used_domain_kind
    this._setIntitialDomainKind();

    // Generate unique, fake query parameters to stop the old images from getting cached
    if (this.logo_url?.indexOf("?") < 0) this.logo_url = `${this.logo_url}?${generateNanoId()}`;
    if (this.favicon_preview_url && this.favicon_preview_url.indexOf("?") < 0) this.favicon_preview_url = `${this.favicon_preview_url}?${generateNanoId()}`;

    if (this.applied_at_sites.items?.length > 0) {
      this.applied_at_sites.items = this.applied_at_sites.items.map((item) => new SiteEntry(item, this));

      if (this.linked_sites.length === 0) {
        this.linked_sites = this.applied_at_sites.items.map((item) => item.id);
      }
    }
  }

  public setWorkingDomains(available_custom_domains: Array<BrandCustomDomainEntry>, available_default_domains: Array<BrandDefaultDomainEntry>): void {
    if (!this.used_custom_domain && available_custom_domains && available_custom_domains.length > 0) {
      this.used_custom_domain = new BrandCustomDomainEntry(available_custom_domains[0]);
    }

    if (!this.used_default_domain) {
      this.used_default_domain = new BrandDefaultDomainEntry(available_default_domains[0]);
    } else if (this.used_default_domain.subdomain && !this.used_default_domain.default_domain_id) {
      this.used_default_domain.default_domain_id = available_default_domains[0].default_domain_id;
      this.used_default_domain.default_domain = new DefaultDomainEntry(available_default_domains[0].default_domain);
    }
  }

  public get linked_sites_total(): number {
    return this.linked_sites.length;
  }

  public get derived_subdomain(): string | null {
    if (this.used_domain_kind === "custom_domain") return null;
    if (this.used_default_domain) return this.used_default_domain.subdomain;
    return "";
  }

  public get derived_uri(): string {
    if (this.used_domain_kind === "custom_domain") {
      if (this.used_custom_domain?.custom_domain) return this.used_custom_domain.custom_domain.domain;
    } else {
      if (this.used_default_domain?.default_domain) return `${this.used_default_domain?.subdomain}.${this.used_default_domain?.default_domain?.domain}`;
    }
    return "";
  }

  public get url(): string {
    return `${LocationEx.protocol}//${this.derived_uri}`;
  }
}

export class BrandListEntry extends BaseListBase<BrandEntry> {}
