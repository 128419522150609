import { SHARED } from "src/app/shared/shared";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { CommonService } from "src/app/shared/services/common.service";
import { AngularSvgIconModule } from "angular-svg-icon";
import { SubSink } from "subsink";
import { timer } from "rxjs";
import { AnalyticsService, METRIC } from "src/app/shared/services/analytics.service";

export class PresentEntry {
  public visible = false;
  public click_position: number;
  public classes = "";
  public src: string;
}

interface I_Presents {
  left: Array<PresentEntry>;
  right: Array<PresentEntry>;
}

interface I_StockingClicks {
  left: number;
  right: number;
}

type T_Position = "left" | "right";

@Component({
  selector: "dentr-stockings",
  templateUrl: "./stockings.component.html",
  standalone: true,
  imports: [SHARED, AngularSvgIconModule],
  host: { class: "absolute w-full top-0 left-0 right-0 h-full overflow-hidden" },
})
export class StockingsComponent implements OnInit, OnDestroy {
  private _subs = new SubSink();
  public presents: I_Presents = {
    left: [],
    right: [],
  };
  public stockingClicks: I_StockingClicks = {
    left: 0,
    right: 0,
  };
  constructor(public commonService: CommonService, private _analyticsService: AnalyticsService) {}

  private _reset(): void {
    this.stockingClicks = {
      left: 0,
      right: 0,
    };
    this.presents = {
      left: [],
      right: [],
    };
  }

  private _handlePresents(position: T_Position): void {
    if (this._totalClicks % 3 === 0 || this._totalClicks === 1) {
      const class_position_x = ["left-0", "right-0"][Math.floor(Math.random() * 2)];
      const class_size = ["w-12", "w-14"][Math.floor(Math.random() * 2)];
      const src = ["xmas-present-1.png", "xmas-present-2.png", "xmas-present-3.png", "xmas-present-4.png", "xmas-present-5.png"][Math.floor(Math.random() * 5)];

      const entry = new PresentEntry();
      entry.click_position = this.stockingClicks[position];
      entry.visible = true;
      entry.classes = `${class_position_x} ${class_size}`;
      entry.src = `assets/${src}`;
      this.presents[position].push(entry);
      timer(4000).subscribe(() => {
        // 4 seconds to match the antimation length
        entry.visible = false;
      });
    }
  }

  private get _totalClicks(): number {
    return this.stockingClicks.left + this.stockingClicks.right;
  }

  public hasVisiblePresents(position: T_Position): boolean {
    return this.presents[position].some((present) => present.visible);
  }

  public ngOnInit() {
    this._subs.sink = this.commonService.onSantaClickReset.subscribe(() => {
      this._reset();
    });
  }

  public stockingClickCount(position: T_Position): void {
    this.stockingClicks[position] = this.stockingClicks[position] + 1;

    void this._analyticsService.track(new METRIC.Manage_StockingClicked(this.stockingClicks[position], position));

    if (this._totalClicks === 12) {
      this.commonService.showSanta = true;
      void this._analyticsService.track(new METRIC.Manage_SantaShown());
      this._reset();
    }

    this._handlePresents(position);
  }

  public ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
